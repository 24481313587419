import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';
import  { Popover, Modal } from 'antd';
import { observer } from "mobx-react";
import userStore from '../store/userStore';
import styles from '../css/TabBar.module.scss';
import Coin from '../img/coin.png'
import ShowPoints from './ShowPoints';
import Loading from './Loading';
import Logo from '../img/logo.png'
import { AnimatedCounter } from  'react-animated-counter';

import ReadingLevelTab from '../img/reading-level-tab.svg'
import PassageTabInac from '../img/passage-tab-inac.svg'
import PassageTabAc from '../img/passage-tab-ac.svg'
import ReadingTabInac from '../img/reading-tab-inac.svg'
import ReadingTabAc from '../img/reading-tab-ac.svg'


function TabBar(props) {
    
    useEffect(() => {
      userStore.refresh()
    }, [])

    const [openModal, setOpenModal] = useState(false)

    const navigate = useNavigate()
  
  
    const showModal = () => {
      setOpenModal(true)
    }
    
    const hideModal = () => {
      setOpenModal(false)
    }

    const handleBoard = () => {
      navigate('/editoral-board')
    }

    const handleReflection = () => {
      navigate('/reflection-and-adjustment')
    }

    const handleHistory = () => {
      navigate('/task-history')
    }

    const logout = () => {
      localStorage.removeItem('token')
      navigate('/login')
    }

    const handleFeedback = () => {
      window.open('https://cosmic-porkpie-7f1.notion.site/Argument-Arena-User-Feedback-35eba80946a14a80982917110b4bad42 ')
    }

    const viewData = () => {
      navigate('/admin-view-data')
    }

    const viewDataReading = () => {
      navigate('/admin-view-data-reading')
    }

    const viewKBData = () => {
      navigate('/admin-view-kb-data')
    }

    const handleHome = () => {
      navigate('/entrance')
    }

    const manageUser = () => {
      navigate('/admin-manage-user')
    }

    const goCRM = () => {
      navigate('/admin-crm')
    }

    const { title, hideInfo, customLogo, step, showHome, experimental } = props
    const userProfile = userStore.profile


    if (!userProfile || userStore.loading) return <Loading />

    return (
      <div className={styles.body}>
        
        <div className={styles.left}>

          {
            customLogo ? <img src={customLogo} className={styles.leftLogo} alt="logo" /> : <img src={Logo} className={styles.leftLogo} alt="logo" />
          }

          {
            title && 
            <div className={styles.title}>
              {title}
            </div>
          }

          {
            step !== undefined && experimental ? <div className={styles.readingTabs}>

              <div className={[styles.tabItem, styles.tabItemAc].join(' ')}>
                <img src={ReadingLevelTab}/>
                <div className={styles.tit}>Choose your reading level</div>
              </div>

              {
                step > 0 ? <div className={styles.divideAc}></div> : <div className={styles.divide}></div>
              }
              

              <div className={[styles.tabItem, step > 0 && styles.tabItemAc].join(' ')}>
                <img src={step > 0 ? PassageTabAc : PassageTabInac}/>
                <div className={styles.tit}>Create your own passage</div>
              </div>

              {
                step > 1 ? <div className={styles.divideAc}></div> : <div className={styles.divide}></div>
              }

              <div className={[styles.tabItem, step > 1 && styles.tabItemAc].join(' ')}>
                <img src={step > 1 ? ReadingTabAc : ReadingTabInac } className={styles.readingTabIcon}/>
                <div className={styles.tit}>Reading Page</div>
              </div>
             
            </div> : step !== undefined && !experimental ? <div className={styles.readingTabs}>

              <div className={[styles.tabItem, styles.tabItemAc].join(' ')}>
                <img src={PassageTabAc}/>
                <div className={styles.tit}>Create your own passage</div>
              </div>

              {
                step > 1 ? <div className={styles.divideAc}></div> : <div className={styles.divide}></div>
              }

              <div className={[styles.tabItem, step > 1 && styles.tabItemAc].join(' ')}>
                <img src={step > 1 ? ReadingTabAc : ReadingTabInac } className={styles.readingTabIcon}/>
                <div className={styles.tit}>Reading Page</div>
              </div>

              </div> : null
          }

        </div>

        {
          !hideInfo &&

          <div className={styles.right}>

            {
              userProfile.userVersion === 1 && (userProfile.isAdmin ? <div className={styles.menu} onClick={handleBoard}>
              Editorial Board
              </div> : 
                <div className={styles.menu} onClick={handleReflection}>
                Reflection & Adjustment
              </div>)
            }

            { userProfile.userVersion === 1 && 
              <div className={styles.menu} onClick={handleHistory}>
                History
              </div>
            }

            { showHome && 
              <div className={styles.menu} onClick={handleHome}>
                Home
              </div>
            }

            <Popover
                content={
                  <div>
                    <Link to='/my-account'><div className={styles.myAccount}>My account</div></Link>
                    {
                      userProfile.isAdmin &&
                      <div>
                        <div className={styles.divider} />
                        <div className={styles.logOut} onClick={viewData}>View data (Origin)</div>
                        <div className={styles.divider} />
                        <div className={styles.logOut} onClick={viewDataReading}>View data (New)</div>
                        <div className={styles.divider} />
                        <div className={styles.logOut} onClick={manageUser}>Manage users</div>
                      </div>
                    }
                    <div className={styles.divider} />
                    <div className={styles.logOut} onClick={logout}>Log out</div>
                  </div>
                }
                arrow={false}
                trigger="click"
                placement="bottomLeft"
                overlayClassName={styles.pop}
              >
              <div className={styles.userInfo}>
                <div className={styles.userName}>{userStore.profile.username}</div>
              </div>
            </Popover>

            <div className={styles.coin} onClick={showModal}>
              <img src={Coin} className={styles.coinIcon}/>
              <AnimatedCounter
                value={userStore.profile.coins}
                includeDecimals={false}
                fontSize='12px'
                incrementColor="#ff6200"
              />
            </div>

          </div>
        }

  
        <Modal
          open={openModal}
          centered
          maskClosable={true}
          width={'65vw'}
          destroyOnClose={true}
          style={{pointerEvents: 'auto', top: 20, marginBottom: 40}}
          modalRender={() => {
            return(<ShowPoints hideModal={() => hideModal} userVersion={userStore.profile?.userVersion}/>)
          }}
        >
        </Modal>

      </div>
    )

  
}


export default observer(TabBar);