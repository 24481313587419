import { makeAutoObservable } from "mobx";
import { boardGroup, getBoardList, getBoardDetail, createLike, submitComment } from "../utils/request";
import { create, persist } from 'mobx-persist'


// Todo 總數據源
class BoardStore {
  group = []
  selectedChallengeId = undefined
  loading = false
  list = []

  review = {
    task: {
      article: '',
      summary: '',
      user: {},
    },
    likes: [],
    comments: [],
  }

  comment = ''

  constructor() {
    makeAutoObservable(this);
  }

  async getGroup() {
    const group = await boardGroup()
    console.log('group', group)
    this.group = group.data
    if (group.data[0]) {
      this.selectedChallengeId = group.data[0].challengeId
      this.fetchList()
    }
    return group
  }

  changeSelected(id) {
    this.selectedChallengeId = id
    this.fetchList()
  }
  
  async fetchList() {
    this.loading = true
    const result = await getBoardList(this.selectedChallengeId)
    this.list = result.data.data
    console.log(result.data, '!~!~!')
    this.loading = false
  }

  async getReviewDetail(id) {
    this.loading = true
    const result = await getBoardDetail(id)
    this.review = result.data
    this.loading = false
  }

  async createLike() {
    this.loading = true
    const result = await createLike(this.review.id)
    this.review = result.data
    this.loading = false
  }

  async submitComment() {
    this.loading = true
    const result = await submitComment({
      reviewId: this.review.id,
      content: this.comment
    })
    this.review = result.data
    this.comment = ''
    this.loading = false
  }
  
  
}

const schema = {
  profile: true
}


const hydrate = create({
  storage: localStorage,   // or AsyncStorage in react-native.
                          // default: localStorage
  jsonify: true  // if you use AsyncStorage, here shoud be true
                  // default: true
})

const boardStore = persist(schema)(new BoardStore())

hydrate('boardStore', boardStore).then(() => console.log('boardStore has been hydrated'))

export default boardStore;
