import React from 'react';
import { useNavigate } from 'react-router';
import styles from '../css/EntryCard.module.scss';
import history from '../img/history-ac.svg'
import achievement from '../img/achievement-ac.svg'
import reading from '../img/reading-ac.svg'
import { Button, Badge } from 'antd';
import { RightOutlined } from '@ant-design/icons';
 
const getIcon = (title) => {
    switch (title) {
        case 'History':
            return history;
        case 'Achievement':
            return achievement;
        case 'Reading':
            return reading;
        default:
            return history;
    }

}

const EntryCard = (props) => {

    const { title, handleJump, disabled, bubble } = props;

    const handleClick = () => {
        handleJump && handleJump()
    }
    
    const content = (
        <div className={styles.wrapper} onClick={handleClick}>
            <img src={getIcon(title)} alt="history" />
            <div className={styles.title}>{title}</div>
            <Button disabled={disabled} className={styles.btn}>Enter <RightOutlined className={styles.icon}/></Button>
        </div>
    )

    return <div className={styles.main}>
        {
            bubble ? <Badge.Ribbon text={bubble} color="red">{content}</Badge.Ribbon> : content
        }
    </div>
    
}

export default EntryCard;