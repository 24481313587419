import React, { useRef, useEffect } from 'react';
import styles from '../css/AiHelps.module.scss'
import { Button, message, Spin, Dropdown } from 'antd'
import Robot from '../img/robot-avatar.png'
import User from '../img/user-avatar.png'
import Like from '../img/like-active.png'
import taskStore from '../store/taskStore'
import { useNavigate } from 'react-router';
import Clock from '../img/clock.png'
import Star from '../img/star.png'
import { observer } from 'mobx-react';
import readingStore from '../store/readingStore';

const AiHelps = (props) => {

    const { hideOperation, hideHelp } = props

    const [askContent, setAskContent] = React.useState('')
    const [loading, setLoading] = React.useState(false)

    const handleCollect = (id, isCollect) => {
        taskStore.collectAiHelp(id, isCollect)
    }

    const  ask = async () => {
        if (loading) return
        if (askContent === '') {
            message.warning('Please input your question.')
            return
        }
        if (!hideHelp && taskStore.task.aiAskLeft === 0) {
            message.warning('You have no more chance to ask AI help.')
            return
        }
        setLoading(true)
        if (hideHelp) {
            const result = await readingStore.ask(askContent)
        } else {
            const result = await taskStore.ask(askContent)
        }
        setAskContent('')
        setLoading(false)
    }

    const [filter, setFilter] = React.useState(false)
    const [aiHelps, setAiHelps] = React.useState([])

    useEffect(() => {
        if (filter) {
            setAiHelps(props.aiHelps.filter(item => item.isCollected ))
        } else {
            setAiHelps(props.aiHelps)
        }

        scrollToBottom()

    }, [filter, props.aiHelps])

    const handleStrategy = async (item) => {
        if (loading) return
        if (taskStore.task.aiStrategyLeft === 0) {
            message.warning('You have no more chance to use strategy AI help.')
            return
        }
        setLoading(true)
        console.log(item)
        const result = await taskStore.strategyHelp({
            strategy: item.strategy,
            challenge: item.challenge,
        })
        setLoading(false)
    }

    const items = taskStore.task.usedStrategies.map(item => {
        return {
            key: item.id,
            label: <div onClick={() => handleStrategy(item)}>{item.strategy}</div>
        }
    })

    const messagesEndRef = useRef(null)

    const scrollToBottom = () => {
        setTimeout(() => {
            messagesEndRef.current?.scrollTo({ behavior: "smooth", top: messagesEndRef.current.scrollHeight})
        }, 100)
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.titleCon}>
                <div className={styles.title}>Chat With Reade</div>
                <div className={styles.btns} >
                    <div className={styles.icon} onClick={() => setFilter(false)}>
                        <img src={Clock} className={styles.clock} />
                    </div>
                    <div className={styles.iconStar} onClick={() => setFilter(true)}>
                        <img src={Star} className={styles.star} />
                    </div>
                </div>
            </div>

            {/* <Spin spinning={loading}> */}
                <div className={[styles.helps, hideOperation? styles.fullScreen : ''].join(' ')} ref={messagesEndRef} >
                    {
                        aiHelps.map((item) => {
                            return (
                                <div>
                                    {
                                        item.role === 'user' && 
                                        <div className={styles.chatRight}>
                                            <div className={styles.rightContentWithSmallMargin}>
                                                {item.showQuestion}
                                            </div>
                                        </div>
                                    }
                                    {
                                        item.role === 'assistant' &&
                                        <div className={styles.chatLeft}>
                                            <div className={styles.iconAndContent}>
                                                <div className={styles.leftAvatar} >
                                                    <img src={Robot} className={styles.robot} />
                                                </div>
                                                <div className={styles.leftContentWithSmallMargin}>
                                                    {item.content}
                                                </div>
                                                
                                            </div>
                                            {
                                                !item.hideSelect && (item.isCollected ?
                                                <div className={styles.like} onClick={() => handleCollect(item.id, false)}>
                                                    <img src={Like}/>
                                                </div> :
                                                <div className={styles.unlike} onClick={() => handleCollect(item.id, true)}>
                                                    <img src={Star} className={styles.unstar}/>
                                                </div>)
                                            }
                                        </div>
                                    }
                                </div>
                            )
                        })
                    }

                    
                </div>
            {/* </Spin> */}

            {
            !hideOperation && !hideHelp && <div className={styles.askHelp}>
                <Dropdown menu={{ items }} placement="top" arrow>
                    <div className={styles.helpBtn}>Help({taskStore.task.aiStrategyLeft})</div>
                </Dropdown>
            </div>
            }
            {
                !hideOperation && 
                <Spin spinning={loading}>
                <div className={styles.cons}>
                    <textarea placeholder='input here...' value={askContent} onChange={e => setAskContent(e.target.value)}> </textarea>

                    <div className={styles.askBtn} onClick={ask}>
                        <div>Ask</div>
                        {!hideHelp && <div>({taskStore.task.aiAskLeft})</div>}
                    </div>
                </div>
            </Spin>
            }

           
        </div>
    )
}

export default observer(AiHelps);