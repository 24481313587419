import React from 'react';
import styles from '../css/ShowPoints.module.scss';
import Coin from '../img/coin.png'
import Close from '../img/close.png'
import Leaderboard from '../img/leader-board.png'
import { Spin } from 'antd';
import { getLeaderBoard } from '../utils/request';
import Deco from '../img/deco.png'

export default class ShowPoints extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      data: [],
    }
    this.fetchData()
  }

  fetchData = async () => {
    const data = await getLeaderBoard()
    this.setState({
      data: data.data,
    })
  }

  

  render() {

    const { userVersion } = this.props

    const content = userVersion === 1 ? [
      {
        describe: '1. Completing autonomous reading',
        details:[{
          describe: '- Strategy Exploration & Insightful Review',
          coin: 100
        }, {
          describe: '- Recommendation of one strategy & technique',
          coin: 20
        }, {
          describe: '- Answer one multiple-choice question correctly',
          coin: 5
        }]
      },{
        describe: '2.  Engage in Editorial Board',
        details:[{
          describe: '- Receive one vote from others',
          coin: 5
        }, {
          describe: '- Give a vote to a review',
          coin: 5
        }, {
          describe: '- Comment on a review',
          coin: 5
        }]
      }
    ] : [
      {
        describe: 'Complete a learning task',
        coin: 20
      }, {
        describe: 'Answer one multiple-choice question correctly',
        coin: 10
      }
    ]


    const Rankings = () => {
      const { loading, data } = this.state 
      return (
        <Spin spinning={loading} style={{marginBottom: 20}}>
          <div className={styles.items}>
          {
            data.map((item, i) => {
              return  <div className={styles.rankItem} key={i}>
                
                {
                  i < 3 ? <img src={require(`../img/Level${i+1}.png`)} className={styles.rankImg}/> :  <div className={styles.rankIndex}>{i+1}</div> 
                }

                <div className={styles.rankName}>{item.username}</div>

                <div className={styles.rankCoin}>  {item.coins} <img src={Coin} className={styles.leftCoin}/></div>

              </div>
            })
          }
          </div>
        </Spin>
      )
    }

    return (
      <div className={styles.body}>
        <img src={Close} className={styles.close} onClick={this.props.hideModal && this.props.hideModal()}/>
        <div className={styles.left}>
          <div className={styles.title}>Leaderboard</div>
          <div className={styles.leftTitle}>How to gain coins?</div>
            <div className={styles.leftContent}>
              {content.map((content, index) => {
                const hasDetail = content.details && content.details.length > 0
                return(
                  <div  className={hasDetail ? styles.hasDetail : styles.noDetail} key={index}>
                      <div className={styles.detailTitle}>{content.describe}</div>
                      {
                        hasDetail ?
                        content.details.map((detail, index) =>
                        <div className={styles.detail} key={index}>
                          {detail.describe}
                          <img src={Coin} className={styles.leftCoin}/>
                          <div className={styles.leftCoinNums}>+{detail.coin}</div>
                        </div>
                        ) :
                        <div className={styles.detail} key={index}>
                          <img src={Coin} className={styles.leftCoin}/>
                          <div className={styles.leftCoinNums}>+{content.coin}</div>
                        </div>
                      }
                  </div>
                  )}
              )}
            </div>
        </div>
        <div className={styles.right}>
          <div className={styles.rightTitle}>{userVersion === 1 ? 'The Best Editor' : 'The diligent reader'}</div>
          <img src={Deco} className={styles.deco} />
          <div className={styles.tabs}>
            <div>Ranking</div>
            <div>Name</div>
            <div>Coin</div>
          </div>
          <div className={styles.rightContent}>
            <Rankings />
          </div>
        </div>
      </div>
    )
  }
  
}
