import React, { useState, useEffect } from 'react';
import { Table, Pagination, Popover, Button } from 'antd';
import { getUserDataReadingReq, getClass } from './utils/request';

import TabBar from './components/TabBar';
import styles from '../src/css/ViewData.module.scss'
import { useNavigate } from 'react-router';

function ViewUserData(props) {

  const [classInfo, setClassInfo] = useState()
  const [userPage, setUserPage] = useState(1)
  const [userData, setUserData] = useState()
  const [loading, setLoading] = useState(false)
  const [classFilter, setClassFilter] = useState()
  const navigate = useNavigate()

  const gotoDetail = (id) => {
    // navigate('/view-user-detail', { state: { id } })
    window.open(`/view-user-detail-reading?id=${id}`)
  }

  useEffect(() => {
    getClassInfo()
  }, [])

  const getClassInfo = async () => {
    const classInfo = await getClass()
    setClassInfo(classInfo.data)
  }


  useEffect(() => {
    getUser()
  }, [userPage, classFilter])

  const getUser = async () => {
    setLoading(true)
    const userData = await getUserDataReadingReq({
      page: userPage,
      pageLimit: 10,
      ...classFilter
    })
    if (userData) {
      setUserData(userData.data)
    }
    setLoading(false)
  }

  const userColumns = [
    {
      title: 'Name',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      key: 'gender',
      render: (_, record) => record.gender === 'M' ? "Male" : "Female",
    },
    {
      title: 'Class',
      dataIndex: 'class',
      key: 'class',
      filters: classInfo && classInfo.map(item => ({ text: item.name, value: item.name })),
      filterMultiple: false,
    },
    
    {
      title: 'Reading',
      dataIndex: 'readings',
      key: 'readings',
      render: (_, record) => {
        const completed = record.readingTasks.filter(item => item.isCompleted)
        return <div>{completed.length} / {record.readingTasks.length}</div>
      }
    },
    {
      title: 'R-coins gained',
      dataIndex: 'coins',
      key: 'coins',
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      key: 'operation',
      width: 200,
      fixed: 'right',
      render: (_, record) => {
        return  <Button size='small' type='primary' onClick={() => gotoDetail(record.id)}>Detail</Button>
      },
    },
    
  ]

  const data = userData && userData.data

  return (
    <div>
      <div className={styles.page}>
        <TabBar />
        <div className={styles.tableOne}>
          <div style={{marginBottom: '20px'}}>Student Contribution Data</div>
          <Table
            className={styles.table}
            columns={userColumns}
            dataSource={data}
            sticky
            bordered
            pagination={false}
            loading={loading}
            onChange={ (pagination, filters) => setClassFilter(filters)}
          />
          <div className={styles.pagination}>
            <Pagination
              current={userPage}
              showTotal={(total) => `Total ${total} items`}
              total={userData && userData.count}
              onChange={(page) => {
                console.log(page);
                setUserPage(page)
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )

  
}


export default ViewUserData;