import React, { useState, useEffect } from 'react';
import { message, Input, Spin, Modal } from 'antd';
import { observer } from "mobx-react";
import { useNavigate } from 'react-router';
import ReviewItem from './components/ReviewItem';

import styles from './css/InsightfulReview.module.scss';
import Notification from './img/notification-black.png'
import NoteIcon from './img/note-icon.png'
import Coin from './img/coin.png'
import AiHelps from './components/AiHelps';
import { useParams } from 'react-router';
import taskStore from './store/taskStore';
import { countWords } from './utils/utils';
import ReadSteps from './components/ReadSteps';

const { TextArea } = Input;

function InsightfulReview(props) {

  const params = useParams();

  useEffect(() => {
    taskStore.getTaskDetail(params.id)
  }, [])

  const navigate = useNavigate()

  const [openSubmitModal, setOpenSubmitModal] = useState(false)


  const submit = () => {
    // if (countWords(taskStore.summary) < 30) {
    //   message.error('Please write at least 30 words in the summary')
    //   return
    // } 
    const reviewStrategies = taskStore.task.reviewStrategies.filter(item => countWords(item.technique) < 10)
    if (reviewStrategies.length > 0) {
      message.error('Please write at least 10 words in the technique')
      return
    }

    setOpenSubmitModal(true)
  }

  const handleOk = async () => {
    setOpenSubmitModal(false)
    await taskStore.submitReview()
    navigate('/main')
  }

  const handleCancel = () => {
    setOpenSubmitModal(false)
  }


  // const countFormatter = (val) => {
  //   const cnt = countWords(val.value)
  //   return <div className={styles.cntWord}>{cnt} words</div>;
  // }

  return (
    <Spin spinning={taskStore.submitLoading || taskStore.loading}>
      <div className={styles.container}>
        <div className={styles.title}>
          Insightful Review 
        </div>

        <ReadSteps current={2} />

        <div className={styles.wrapper}>
          <div className={styles.wrapperLeft}>
            <div className={styles.table}>
              <div className={styles.tableTitle}>
                <div className={styles.titleDesc1}>Challenge</div>
                <div className={styles.titleDesc2}>Effective Strategy</div>
                <div>Effective Technique <div className={styles.hint}>(a specific method or procedure used to implement a strategy)</div></div>
              </div>
              <div className={styles.reviewItems}>
                <ReviewItem />
              </div>
            </div>
            <div className={styles.note}>
              <div className={styles.noteTitle}>
                <div className={styles.noteIcon}><img src={NoteIcon} /></div>
                NOTE
              </div>
              <div className={styles.noteContent}>
                {
                  taskStore.task.note
                }
              </div>
            </div>
          </div>
          <div className={styles.wrapperRight}>
              <AiHelps aiHelps={taskStore.task.aiHelps} hideOperation/>
          </div>
        </div>

        {
           taskStore.task.step !== 3 && <div className={styles.footer}>
            <div className={styles.footerLeft}>
              <div className={styles.notice}>Attention</div>
              <div className={styles.notice}>Are you sure you want to recommend these strategies and techniques?</div>
              <div className={styles.notice}>You won’t be able to revise or delete them after recommending.</div>
            </div>
            <div className={styles.footerRight} onClick={submit}>
              <div>Recommend</div>
              <div className={styles.buttonDivider} />
              +100
              <div className={styles.buttonCoin}>
                <img src={Coin} />
              </div>
            </div>
          </div>
        }
        

        <Modal
            open={openSubmitModal}
            centered
            width={"37.58vw"}
            closable={false}
            footer={null}
          >
            <div className={styles.modal}>
              <div className={styles.modalContent}>Congratulations! You’ve done a great job! Are you sure to submit your recommendations?</div>
              <div className={styles.btnGroup}>
                  <div className={styles.modalConfirm} onClick={handleOk} >Confirm</div>
                  <div className={styles.modalCancel} onClick={handleCancel}>Cancel</div>
                </div>
            </div>
        </Modal>

      </div>
    </Spin>
  )
}


export default observer(InsightfulReview);