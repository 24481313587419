import React, { useRef, useEffect, useState } from 'react';
import { observer } from "mobx-react";
import { Input } from 'antd'
import styles from '../css/ReviewItem.module.scss'

import Delete from '../img/delete-icon.png'
import Coin from '../img/coin.png'
import taskStore from '../store/taskStore';

const { TextArea } = Input;

function ReviewItem (props) {

    return (
        <div className={styles.container}>
            {
              taskStore.task.reviewStrategies && taskStore.task.reviewStrategies.map((e, i) => {
                return (
                    <div key={e.strategyId} className={styles.item}>
                        
                        <div className={styles.deleteIcon}>
                        {
                            taskStore.task.step !== 3  && <img src={Delete} onClick={() => taskStore.deleteReviewStrategy(i)}/>
                        }
                        </div>
                        <div className={styles.operationContainer}>
                            <div className={styles.button}>{e.challenge}</div>

                            <div  className={styles.button}>{e.strategy}</div>
                            {/* <div className={styles.input}> */}
                                <TextArea className={styles.text} autoSize={{ minRows: 3, maxRows: 6 }} bordered={false} disabled={ taskStore.task.step === 3} placeholder='no less than 10 words' value={e.technique} onChange={x => {e.technique = x.target.value}}/>
                            {/* </div> */}
                            <div className={styles.coinContainer}>
                                <div>+20</div>
                                <div className={styles.coinIcon}><img src={Coin}/></div>
                            </div>
                        </div>
                        
                    </div>
                )
              })
            }
        </div>
    )
}

export default observer(ReviewItem)
