import React, { useState, useEffect } from 'react';
import { message, Modal, Table, Radio, RadioChangeEvent, Button } from 'antd';
import { observer } from "mobx-react";

import styles from '../css/SelfAssessment.module.scss';
import ReadMateRate from '../components/ReadMateRate';
import taskStore from '../store/taskStore';
import { useNavigate } from 'react-router';
import userStore from '../store/userStore';

function SelfAssessment(props) {

  const { show } = props

  const submit = async () => {
    if(taskStore.selfAssessment.entertainment === 0 || taskStore.selfAssessment.interesting === 0 || taskStore.selfAssessment.like === 0 || taskStore.selfAssessment.concentrate === 0 || taskStore.selfAssessment.understand === 0 || taskStore.selfAssessment.focus === 0 || taskStore.selfAssessment.suppose === 0 || taskStore.selfAssessment.perform === 0 || taskStore.selfAssessment.hard === 0) {
      message.error('Please finish all the questions')
      return
    }
    let map = new Map()
    for(let key in taskStore.selfAssessment) {
      map.set(taskStore.selfAssessment[key], 1)
    }
    if(map.size === 1) {
      message.error('Please select different answers')
      return
    }
    taskStore.submitAssessment()
    // 跳转到下一个页面
    // navigate(`/insightful-review/${taskStore.task.id}`)
  }


  return (
      <Modal
        open={show}
        centered
        footer={null}
        maskClosable={userStore?.profile?.isAdmin}
        closable={userStore?.profile?.isAdmin}
        width={"60vw"}
        moveable
        onCancel={() => taskStore.showAssessment = false}
      >
        <div className={styles.modal}>
          <div className={styles.modalTitle}>Self-reflection</div>
          <div className={styles.modalSubTitle}>(Please fill in this online survey)</div>

          <div className={styles.hint}>
            <div className={styles.first}>Direction: please indicate the extent to which you agree or disagree with the following statements</div>
            <div className={styles.second}>1 = Strongly disagree; 2 = Disagree; 3 = Slightly disagree; 4 = Slightly agree; 5 = Agree; 6 = Strongly agree</div>
          </div>

          <div className={styles.modalContent}>
            <div className={styles.modalQuestion}>
              1. All in all, this activity was entertaining. 
            </div>
            <div className={styles.modalAnswerRate}>
              <div className={styles.modalRate}>
                <ReadMateRate step={6} score={taskStore.selfAssessment.entertainment} onChange={e => taskStore.selfAssessment.entertainment = e} />
              </div>
            </div>
            <div className={styles.modalQuestion}>
              2. The activity was very interesting to me.
            </div>
            <div className={styles.modalAnswerRate}>
              <div className={styles.modalRate}>
                <ReadMateRate step={6} score={taskStore.selfAssessment.interesting} onChange={e => taskStore.selfAssessment.interesting = e} />
              </div>
            </div>
            <div className={styles.modalQuestion}>
              3. I really liked this activity.
            </div>
            <div className={styles.modalAnswerRate}>
              <div className={styles.modalRate}>
                <ReadMateRate step={6} score={taskStore.selfAssessment.like} onChange={e => taskStore.selfAssessment.like = e} />
              </div>
            </div>
            
            <div className={styles.modalQuestion}>
              4. During this activity, I concentrated hard on it.
            </div>
            <div className={styles.modalAnswerRate}>
              <div className={styles.modalRate}>
                <ReadMateRate step={6} score={taskStore.selfAssessment.concentrate} onChange={e => taskStore.selfAssessment.concentrate = e} />
              </div>
            </div>

            <div className={styles.modalQuestion}>
              5. I was completely focused on trying to understand every single word.
            </div>
            <div className={styles.modalAnswerRate}>
              <div className={styles.modalRate}>
                <ReadMateRate step={6} score={taskStore.selfAssessment.understand} onChange={e => taskStore.selfAssessment.understand = e} />
              </div>
            </div>

            <div className={styles.modalQuestion}>
              6. I was completely focused on the content.
            </div>
            <div className={styles.modalAnswerRate}>
              <div className={styles.modalRate}>
                <ReadMateRate step={6} score={taskStore.selfAssessment.focus} onChange={e => taskStore.selfAssessment.focus = e} />
              </div>
            </div>

            <div className={styles.modalQuestion}>
              7. I am doing what I’m supposed to be doing.
            </div>
            <div className={styles.modalAnswerRate}>
              <div className={styles.modalRate}>
                <ReadMateRate step={6} score={taskStore.selfAssessment.suppose} onChange={e => taskStore.selfAssessment.suppose = e} />
              </div>
            </div>

            <div className={styles.modalQuestion}>
              8. I put effort into performing the best that I can.
            </div>
            <div className={styles.modalAnswerRate}>
              <div className={styles.modalRate}>
                <ReadMateRate step={6} score={taskStore.selfAssessment.perform} onChange={e => taskStore.selfAssessment.perform = e} />
              </div>
            </div>

            <div className={styles.modalQuestion}>
              9. I work hard to complete the task in hand.
            </div>
            <div className={styles.modalAnswerRate}>
              <div className={styles.modalRate}>
                <ReadMateRate step={6} score={taskStore.selfAssessment.hard} onChange={e => taskStore.selfAssessment.hard = e} />
              </div>
            </div>

          </div>
          <div className={styles.modalBottom}>
            <Button onClick={submit} disabled={userStore?.profile?.isAdmin} type="primary" size="large" loading={taskStore.submitLoading} className={styles.submitBtn}>Submit</Button>
          </div>
        </div>
      </Modal>
  )
}


export default observer(SelfAssessment);