import React from 'react'
import * as echarts from 'echarts';
import styles from '../css/Chart.module.css'

export default class ChartThree extends React.Component {

  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.renderChat()
  }

  componentDidUpdate() {
    this.renderChat()
  }

  renderChat() {
    const {data} = this.props
    if (!data) return
    
    const sum = data.mine.reduce((a, b) => a + b, 0)

    const chartDom = document.getElementById('ChartThree');
    const myChart = echarts.init(chartDom);
    const option = {
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        bottom: '30px'
      },
      radar: {
        indicator: [
          { name: 'Motivation', max: sum },
          { name: 'Behavior', max: sum },
          { name: 'Cognition', max: sum },
        ]
      },
      series: [
        {
          name: 'Progress of strategy use',
          type: 'radar',
          data: [
            {
              value: data.mine,
              name: 'Progress of strategy use'
            }
          ]
        }
      ]
    };

    myChart.setOption(option);
  }

  render() {

    return (
      <div id="ChartThree" className={styles.chartThree}>
      </div>
    )
  }
  
}
