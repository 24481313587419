import React, { useState, useEffect } from 'react';
import { message, Modal, Table, Radio, RadioChangeEvent, Button } from 'antd';
import { observer } from "mobx-react";
import styles from '../css/Exercises.module.scss';
import readingStore from '../store/readingStore';
import { useNavigate } from 'react-router';
import userStore from '../store/userStore';
import { ExercisesOptions } from '../utils/constant';

function Exercises(props) {

  const { show } = props

  const navigate = useNavigate()

  const submit = async () => {
    readingStore.submitExercises()
  }

  const handleContinue = async () => {
    // 跳转到下一个页面
    readingStore.showExercises = false
    if (userStore.profile.hasSurvey && !readingStore.reading.survey) {
      readingStore.showSurvey = true
    } else {
      navigate(`/entrance`)
    }
  }

  console.log(readingStore.exerciseAnswers, '!!!')

  return (
      <Modal
        open={show}
        centered
        closeIcon={<div className={styles.closeIcon}>Back to reading</div>}
        footer={null}
        width={"60vw"}
        onCancel={() => readingStore.showExercises = false}
      >
        <div className={styles.modal}>
          <div className={styles.modalTitle}>Reading Comprehension Exercise</div>
          <div className={styles.modalContent}>

            {
              readingStore.reading.exercises && readingStore.reading.exercises.map((item, index) => {
                return <>
                <div className={styles.modalQuestion}>
                  {index + 1}. {item.question}
                </div>
                <div className={styles.modalAnswerRate}>
                  <Radio.Group disabled={readingStore.showAnswer} onChange={e => readingStore.exerciseAnswers[index] = e.target.value} value={readingStore.exerciseAnswers[index]}>
                    {
                      item.options &&
                      item.options.map((e, index) => 
                        <Radio value={index}>{ExercisesOptions[index]}. {e}</Radio>
                      )
                    }
                  </Radio.Group>
                  {
                    readingStore.showAnswer && <div className={styles.correctAnswer}>
                      <div className={[styles.modalAnswer, item.correctAnswer === readingStore.exerciseAnswers[index] ? styles.green : styles.red].join(' ')}>
                        Correct Answer: {ExercisesOptions[item.correctAnswer]}
                      </div>
                      <div className={[styles.modalAnswer, item.correctAnswer === readingStore.exerciseAnswers[index] ? styles.green : styles.red].join(' ')}>
                        Explanation: {item.explanation}
                      </div>
                    </div>
                  }
                </div>
                </>
              })
            }
            
          </div>

          {
            readingStore.showAnswer ? 
            <div className={styles.modalBottom}>
              <Button onClick={handleContinue} type="primary" size="large" loading={readingStore.submitLoading} className={styles.submitBtn}>Finish</Button>
            </div> :
            <div className={styles.modalBottom}>
              <Button onClick={submit} disabled={readingStore.exerciseAnswers?.length !== readingStore?.reading?.exercises?.length} type="primary" size="large" loading={readingStore.submitLoading} className={styles.submitBtn}>Answer</Button>
            </div>
          }
         
        </div>
      </Modal>
  )
}


export default observer(Exercises);