import React from 'react'
import { Button, message } from 'antd';
import styles from '../css/GoatList.module.scss'
import Selector from './Selector'
import { PlusSquareOutlined } from '@ant-design/icons';
import GoatListItem from './GoatListItem'
import { observer } from 'mobx-react';
import planStore from '../store/planStore';
import Notification from '../img/notification.png'

 class GoatList extends React.Component {

  constructor(props) {
    super(props)
  }

  

  

  render() {
    const { list } = planStore

    return (
      <div className={styles.wrapper} >

          <div className={styles.title}>
            <div>The challenge I may have <a className={styles.optionIcon} href="https://periwinkle-slash-7d2.notion.site/Self-regulated-reading-strategies-7e86c119b2e74f3e916f3ef9b7373c78?pvs=4" target="_blank">
                <img className={styles.notification} src={Notification} />
              </a></div>
            <div>The strategy that may help me</div>
          </div>

          <div className={styles.list}>
              {
                list.map((item, index) => {
                  return (
                    <GoatListItem onChange={(value) => planStore.changeItem(index, value)} item={item} onDelete={() => planStore.delete(index)} index={index} />
                  )
                })
              }
          </div>

          <div className={styles.add}>
            <Button size='large' onClick={planStore.add} className={styles.btn} icon={<PlusSquareOutlined style={{color: 'rgba(47, 178, 85, 1)'}}/>} type="dashed" >  Add one more（up to 6） </Button>
          </div>

      </div>
    )
  }
  
}

export default observer(GoatList)