import { makeAutoObservable } from "mobx";
import { createReadingTask, discoverReading, saveReadingLevel, getReadingTaskDetail, selectReading, updateReadingDetail, countNewReadingTime, submitReading, generateReadingExercises, submitReadingExercises, submitReadingSurvey, readingAiAskHelp } from '../utils/request'
import { message } from 'antd';
import { create, persist } from 'mobx-persist'
import userStore from "./userStore";

// Todo 總數據源
class ReadingStore {

  reading =  {
    aiReadings: [],
    aiReadingLeft: 3,
    level: null,
    note: '',
    step: 0,
    exercises: [],
    aiHelps: [],
  }

  timer = undefined

  survey = {
    interesting: 0,
    enjoy: 0
  }

  showSurvey = false
  
  genreType = undefined
  readingLevel = undefined
  articleLength = undefined

  loading = false
  submitLoading = false

  exerciseAnswers = []
  showExercises = false
  showAnswer = false
  generateLoading = false

  constructor() {
    makeAutoObservable(this);
  }

  startCount() {
    if (this.reading.isCompleted) {
      return 
    }
    this.timer = setInterval(() => {
      this.countReadingTime()
    }, 10000)
  }

  finishCount() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  ask = async(content) => {
    const result = await readingAiAskHelp({
      readingId: this.reading.id,
      question: content
    })
    this.reading.aiHelps = this.dealWithAiHelps(result.data.aiHelps)
    return result
  }

  async countReadingTime() {
    console.log('~!~!')
    await countNewReadingTime(this.reading.id)
  }

  async getReadingTaskDetail(id) {
    this.loading = true
    const result = await getReadingTaskDetail(id)
    const {aiReadings, aiHelps, ...rest} = result.data
    this.reading = rest
    if (result.data.survey) {
      this.survey = result.data.survey
    }
    if (result.data.exercises && result.data.exercises.length > 0 && result.data.exercises[0].answer !== null) {
      this.exerciseAnswers = []
      this.exerciseAnswers = result.data.exercises.map(item => item.answer)
    }
    this.reading.aiReadings = this.dealWithAiArticles(result.data.aiReadings)
    this.reading.aiHelps = this.dealWithAiHelps(result.data.aiHelps)
    this.loading = false
    return result
  }

  async submitLevel() {
    this.loading = true
    const result = await saveReadingLevel({
      readingId: this.reading.id,
      readingLevel: this.reading.readingLevel,
    })
    this.reading = result.data
    this.reading.aiReadings = this.dealWithAiArticles(result.data.aiReadings)
    this.reading.aiHelps = this.dealWithAiHelps(result.data.aiHelps)
    this.loading = false
    return result 
  }

  async updateReadingDetail(params) {
    if (this.reading.isCompleted) {
      return
    }
    const result = await updateReadingDetail({
      id: this.reading.id,
      ...params
    })
    return result
  }

  deleteReviewStrategy(index) {
    if (this.reading.reviewStrategies.length === 1) {
      message.warning('You must have at least one review strategy.')
      return
    }
    this.reading.reviewStrategies.splice(index, 1)
  }

  async createReading() {
    const result = await createReadingTask()
    return result
  }

   discoverArticle = async(hasInterest) => {
    if (this.loading) return 

    if(this.reading.aiReadingLeft <= 0) {
      message.warning('You have no more chance to discover articles.')
      return
    }

    if (userStore.profile.experimental) {
      // 校验
      if (this.genreType === undefined) {
        message.warning('Please select the genre of article.')
        return
      } else if (this.articleLength === undefined) {
        message.warning('Please select the article length.')
        return
      } else if (hasInterest && !this.interest ) {
        message.warning('Please input the topic of your personal interests.')
        return
      } 
    }

    // 请求
    this.loading = true
    const result = await discoverReading({
      readingId: this.reading.id,
      genreType: this.genreType,
      articleLength: this.articleLength,
      interest: this.interest
    })

    this.reading = result.data
    this.reading.aiReadings = this.dealWithAiArticles(result.data.aiReadings)
    this.reading.aiHelps = this.dealWithAiHelps(result.data.aiHelps)
    this.loading = false
  }

  dealWithAiArticles = (aiArticles) => {
    
    const returnHelps = [
      {
        role: 'assistant',
        content: 'Welcome to ReadMate! I\'m Reade, and I\'m here to help you find reading articles according to your selection.',
        hideSelect: true
      },
      ...aiArticles
    ]
    return returnHelps
  }

  dealWithAiHelps = (aiArticles) => {
    if (!aiArticles) {
      return [
        {
          role: 'assistant',
          content: 'Hello! I am here to assist you with your reading journey. If you have any questions about the article, please type them in below and click the “ASK”.',
          hideSelect: true
        },
      ]
    }
    
    const returnHelps = [
      {
        role: 'assistant',
        content: 'Hello! I am here to assist you with your reading journey. If you have any questions about the article, please type them in below and click the “ASK”.',
        hideSelect: true
      },
      ...aiArticles
    ]
    return returnHelps
  }

  selectArticle = async (id) => {
    this.loading = true
    try {
      const result = await selectReading({
        readingId: this.reading.id,
        articleId: id,
      })
      this.reading = result.data
      this.reading.aiHelps = this.dealWithAiHelps(result.data.aiHelps)
      this.loading = false
      return result

    } catch(e) {
      this.loading = false
    }
  }

  submitReading = async() => {
    this.loading = true
    const result = await submitReading({
      id: this.reading.id,
      note: this.reading.note,
      articleRichText: this.reading.articleRichText
    })
    this.loading = false
    return result
  
  }

  submitExercises = async() => {
    this.submitLoading = true
    const result = await submitReadingExercises({
      readingId: this.reading.id,
      exerciseAnswers: this.exerciseAnswers
    })
    this.showAnswer = true
    this.submitLoading = false
    return true
  }

  submitSurvey = async() => {
    this.submitLoading = true
    const result = await submitReadingSurvey({
      readingId: this.reading.id,
      ...this.survey
    })
    this.reading.survey = result.data.survey
    this.submitLoading = false
    this.survey = {
      interesting: 0,
      enjoy: 0
    }
    return result
  }

  generateExercises = async () => {
    if (this.reading.exercises && this.reading.exercises.length > 0) {
      this.showExercises = true
      return
    }
    this.generateLoading = true
    const result = await generateReadingExercises(this.reading.id)
    this.generateLoading = false
    this.reading.exercises = []
    this.reading.exercises = result.data.exercises
    this.showAnswer = false
    this.exerciseAnswers = []
    this.showExercises = true
    
    if (this.reading.exercises[0].answer !== null) {
      this.exerciseAnswers = []
      this.showAnswer = true
      this.exerciseAnswers = this.reading.exercises.map(item => item.answer)
    }
    
  }

}

const readingStore = new ReadingStore()
export default readingStore;