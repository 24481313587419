import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { Spin, message } from 'antd';
import Coin from './img/coin.png'
import { userLogin } from './utils/request';
import { Divider } from 'antd';
import styles from './css/Login.module.scss';
import logo from './img/logo.png';
import loginAccount from './img/login-account.png';
import loginPsw from './img/login-psw.png';
import beian from './img/beian.png';

export default function Login () {

        const navigate = useNavigate()

        const [email, setEmail] = useState('')
        const [password, setPassword] = useState('')
        const [loading, setLoading] = useState(false)

        const login = async () => {
            setLoading(true)
            try {
                const result = await userLogin({ email, password })
                setLoading(false)
                console.log('result', result)
                if (result.code == 0 ) {
                    if(result.data.isAdmin) {
                        navigate('/admin-manage-user', {replace: true})
                    } else if (result.data.needInitialize && result.data.userVersion === 1) {
                        navigate('/create-plan', {replace: true})
                    } else {
                        if(result.data.userVersion === 1) {
                            navigate('/main', {replace: true})
                        } else if (result.data.userVersion === 2) {
                            navigate('/entrance', {replace: true})
                        }
                    }
                }
            } catch (e) {
                setLoading(false)
            }
          
        }

        const canLogin = email && password

        const showHint = () => {
            let hint = ''
            if (!email) {
                hint = 'Please enter your email'
            } else if (!password) {
                hint = 'Please enter your password'
            }
            if (hint) {
                message.warning(hint)
            }

        }

        return (
            <div className={styles.body}>
                <div className={styles.logIn}>
                    <div className={styles.left}>
                        <img src={logo} className={styles.leftLogo} alt="logo" />
                        <div className={styles.leftContent}>
                            <div>Welcome to ReadMate, an AI-powered platform designed to enhance your reading skills and promote autonomous learning. We are thrilled to have you on board as an editor, where you and other editors share a mission: crafting the Graded Handbook, <i>The Handbook for Becoming an Autonomous Reading Master</i>. In this Handbook, you can share your unique reading experience and recommend valuable strategies to guide readers in becoming autonomous reading masters. Throughout this process, ReadMate will serve as your reading companion, empowering you with its ability to facilitate article discovery, offer timely feedback, and provide strategic immersion experiences.</div>

                            <div className={styles.contentBold}>Let's explore the two parts of this platform:</div>

                            <div className={styles.express}>

                                <div className={styles.title}> Part 1: Embark on a Journey of Autonomous Reading </div>
                                <div className={styles.exp}> In this part, you will immerse yourself in the world of autonomous reading. Here’s what you’ll be doing: </div>

                                <div className={styles.subTitle}> <span><b>Goal Pursuit:</b> Set weekly goals for the number of articles to read and the dedicated reading time; identify specific challenges you may encounter and develop personalized strategies to overcome them during reading.</span> </div>
                                <div className={styles.subTitle}> <span><b>Article Discovery:</b> Choose articles that align with your reading level and other preferences with the assistance of ReadMate.</span> </div>
                                <div className={styles.subTitle}> <span><b>Strategy Exploration:</b> Immerse yourself in the selected articles and apply personalized strategies. ReadMate will accompany you, offering guidance and techniques on these strategies and providing timely feedback.</span> </div>
                                <div className={styles.subTitle}> <span><b>Reflection & Adjustment:</b> Take time each week to reflect on your performance, identify areas of improvement,  and adjust goals for the upcoming week.</span> </div>

                                <Divider />

                                <div className={styles.title}> Part 2: Handbook Creation through Recommended Strategy </div>
                                <div className={styles.exp}> In this part, you have the wonderful opportunity to share your valuable insights and recommendations with the editorial board community for creating Graded Handbook. Here’s how you’ll contribute: </div>

                                <div className={styles.subTitle}>  <span><b>Insightful Reviews:</b> Drawing from your firsthand experience from Part 1, write comprehensive reviews where you share insights and recommend the most effective strategies and techniques you have discovered.</span> </div>
                                <div className={styles.subTitle}>  <span><b>Community Engagement:</b> Engage in discussions within the editorial board community, where you can actively share, discuss, and vote for strategies and techniques that will be included in the Graded Handbook. </span> </div>

                            </div>

                            <div>As you progress through each part, you’ll earn R-Coins  <img src={Coin} className={styles.coin}/> that contribute to your position on the leaderboard. Join ReadMate now and become a top editor in our vibrant community!</div>
                            <p className={styles.xxLinks}>
                                <a className={styles.xxLink} target="_blank" href="https://periwinkle-slash-7d2.notion.site/ReadMate-User-Manual-108d4b3f7e7a41d0b95f353540f50935?pvs=4" >ReadMate User Manual</a>
                                <a className={styles.xxLink} target="_blank" href="https://forms.office.com/Pages/ResponsePage.aspx?id=DQSIkWdsW0yxEjajBLZtrQAAAAAAAAAAAAN__lF8WM9UMUVBSzNYQjlCNFRCODNIUkcxRjVFNlRCOS4u">Feedback and bug reports are welcome!</a>
                            </p>
                        
                        </div>
                    </div>
                    <Spin spinning={loading}>
                        <div className={styles.right}>
                            <div className={styles.rightBox}>
                                <div className={styles.rightTitle}>Log in to ReadMate</div>
                                    <div className={styles.account}>
                                        <img src={loginAccount} className={styles.accountImg} />
                                        <input placeholder='Email' className={styles.input} value={email} onChange={(e) => setEmail(e.target.value)}></input>
                                    </div>
                                    <div className={styles.password}>
                                        <img src={loginPsw} className={styles.pswImg} />
                                        <input type="password" placeholder='Password' className={styles.input} value={password} onChange={(e) => setPassword(e.target.value)}></input>
                                    </div>
                                
                                <div className={canLogin ? styles.buttonNormal : styles.buttonDisable} onClick={canLogin ? login : showHint}>
                                    Log in
                                </div>

                                <div className={styles.loginHint}>
                                    By using ReadMate, you agree to our <a className={styles.underLink} target="_blank" href="https://periwinkle-slash-7d2.notion.site/Terms-of-Service-b2d3358f5bc746df900192c934ecdb8e?pvs=4">Terms of Service</a> and <a className={styles.underLink}  target="_blank" href="https://periwinkle-slash-7d2.notion.site/Privacy-Policy-b32b591589e54bc9806953dd90eb97e2?pvs=4">Privacy Policy</a>. 
                                </div>
                            </div>
                        </div>
                    </Spin>
                </div>
                <a className={styles.link} target="_blank" href='https://www.notion.so/Development-Team-f3b89ffc8f0741359308c3ba61cc6f82?pvs=4'>
                    Our development team
                </a>

                {/* <footer data-v-23a0b172="" class="el-footer main-footer" style="height: 60px;"></footer> */}

                <div className={styles.beian}>
                    <img src={beian} width="20" />
                    <a className={styles.beianLink} target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=34040002000383" >
                        <span>皖公网安备 34040002000383号</span>
                    </a>
                    <a className={styles.beianLink} target="_blank" href="https://beian.miit.gov.cn">
                        <span>皖ICP备2023020528号-1</span>
                    </a>
                </div>
            </div>
        )
    }
