import React from 'react'
import { InputNumber } from 'antd';
import styles from '../css/FrequencyBox.module.scss'
import Selected from '../img/selected.png'
export default class FrequencyBox extends React.Component {

  constructor(props) {
    super(props)
  }


  render() {
    const { selected, onSelect, title, subTitle, onChange } = this.props

    return (
      <div className={[styles.wrapper, selected ? styles.selectWrapper : ''].join(' ')} onClick={onSelect} >
          <div className={styles.title}>{title}</div>
          <div className={styles.subTitle}>{subTitle}</div>

          <div className={styles.mins}><InputNumber min={1} max={9999} onChange={onChange} className={styles.input}/> mins</div>
          {
            selected && <img src={Selected} className={styles.selected}/>

          }
      </div>
    )
  }
  
}
