import styles from '../css/Background.module.scss'
import slogan from '../img/slogan.png'

const Background = ({showSlogan}) => {
    return <div className={styles.main}>
        <div className={styles.bgy}>
        </div>
        <div className={styles.bgg}>
        </div>

        {showSlogan && <img src={slogan} className={styles.slogan}/>}
    </div>
}

export default Background;