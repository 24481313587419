import React, { useEffect } from 'react'
import { Button, message } from 'antd';
import styles from '../css/GoatList.module.scss'
import { getChallenge } from '../utils/request'
import Selector from './Selector'
import { PlusSquareOutlined, CloseSquareOutlined } from '@ant-design/icons';
import planStore from '../store/planStore'
import { observer } from "mobx-react";

function GoatListItem (props) {

    const { index, onDelete, onChange, item } = props

    const {challengeId, strategyId } = item

    useEffect(() => {
      planStore.fetchList()
    }, [])

    const { strategyOptions, challengeOptions, list } = planStore

    const options = challengeId ? strategyOptions.filter(item => {
      return item.challengeId === challengeId
    }).map(item => {
      return {
        value: item.id,
        label: item.strategy
      }
    }) : []

    const chaOptions = challengeOptions.map(item => {
      return {
        ...item,
        options: item.options.map(item => {
          return {
            ...item,
            disabled: list.some(i => i.challengeId === item.value)
          }
        })
      }
    })

    // console.log(challengeId, options, strategyOptions)

    return (
      <div className={styles.item}>

          <div className={styles.index}>{index + 1}</div>

          <div className={styles.challenge}>
            <Selector onChange={e => onChange({challengeId: e, strategyId: undefined})} value={challengeId} placeholder="Challenge" width={300} options={chaOptions} />
          </div>

          <div className={styles.strategy}>
            <Selector onChange={e => onChange({strategyId: e})} value={strategyId} placeholder="Planning Strategy" width={300} options={options} />
          </div>

          <CloseSquareOutlined onClick={onDelete} className={styles.delete} style={{color: 'rgba(222, 222, 222, 1)'}} />

      </div>
    )
}  

export default observer(GoatListItem)