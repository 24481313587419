import React, { useRef, useEffect } from 'react';
import styles from '../css/ReadingConversation.module.scss'
import { Button } from 'antd'
import Robot from '../img/robot-avatar.png'
import User from '../img/user-avatar.png'
import Like from '../img/like-active.png'
import readingStore from '../store/readingStore'
import { useNavigate } from 'react-router';

export default (props) => {
    const { conversations, hasLikeIcon } = props
    const handleSelect = async (id) => {
        await readingStore.selectArticle(id)
    }

    return (
        <div className={styles.wrapper}>
            <div>
                {
                    conversations.map((item) => {
                        return (
                            <div>
                                {
                                    item.role === 'user' && 
                                    <div className={styles.chatRight}>
                                        <div className={hasLikeIcon ? styles.rightContentWithSmallMargin : styles.rightContent}>
                                            {item.showQuestion}
                                        </div>
                                        <div className={styles.rightAvatar} >
                                        <img src={User} className={styles.user} />
                                        </div>
                                    </div>
                                }
                                {
                                    item.role === 'assistant' &&
                                    <div className={styles.chatLeft}>
                                        <div className={styles.iconAndContent}>
                                            <div className={styles.leftAvatar} >
                                                <img src={Robot} className={styles.robot} />
                                                <div>Reade</div>
                                            </div>
                                            <div className={hasLikeIcon ? styles.leftContentWithSmallMargin : styles.leftContent}>
                                                {item.content}

                                                {
                                                    !item.hideSelect &&
                                                    <div className={styles.btnWrapper}>
                                                        <Button onClick={() => handleSelect(item.id)} className={styles.selectBtn} type="primary"> Select </Button>
                                                    </div>
                                                }
                                            </div>
                                            
                                            
                                        </div>
                                        {
                                            hasLikeIcon &&
                                            <div className={styles.like}>
                                                <img src={Like}/>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
