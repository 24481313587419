import React, { useState, useEffect } from 'react';
import { message, Spin, Table, Radio, RadioChangeEvent, Button } from 'antd';
import { observer } from "mobx-react";
import dayjs from 'dayjs'
import TabBar from './components/TabBar';
import styles from './css/EditorDiscussion.module.scss';
import LikeActive from './img/gesture-like-active.png'
import LikeInactive from './img/gesture-like-inactive.png'
import Coin from './img/coin.png'
import Comment from './img/comment-icon.png'
import { useParams } from 'react-router';
import boardStore from './store/boardStore';
import userStore from './store/userStore';
import { countWords } from './utils/utils';

function EditorDiscussion(props) {

  const likeStatus = boardStore.review.likes && boardStore.review.likes.find(item => {
    return item.userId === userStore.profile?.id
  })

  const hasCommented = boardStore.review.comments && boardStore.review.comments.find(item => {
    return item.uid === userStore.profile?.id
  })

  const isSelf = boardStore.review.task?.user?.id === userStore.profile?.id || (userStore.profile && userStore.profile.isAdmin)

  const commentCount = boardStore.review.comments?.length

  const params = useParams()

  useEffect(() => {
    boardStore.getReviewDetail(params.id)
  }, [params.id])

  const handleLike = () => {
    if (!likeStatus) {
      boardStore.createLike()
    }
  }

  const commentList = boardStore.review.comments

  const submit = () =>  {
      if(countWords(boardStore.comment) < 30) {
        message.error('Please enter at least 30 words')
        return
      }
      boardStore.submitComment()
  }

  return (
    <div>
      <TabBar title="Editor Discussion"/>
      <Spin spinning={boardStore.loading}>
        <div className={styles.container}>

          <div className={styles.containerLeft}>
            {/* <div className={styles.leftTitle}>Editor Discussion </div> */}
            <div className={styles.leftSubTitle}>Article</div>
            <div className={styles.articleContent}>
              {
                boardStore.review.task.article
              }
            </div>
            <div className={styles.leftSubTitle}>Summary</div>
            <div className={styles.summaryContent}>
              {
                boardStore.review.task.summary
              }
            </div>
          </div>
          <div className={styles.containerRight}>
            <div className={styles.recommendedContainer}>
              <div className={styles.recommendedInfo}>
                <div className={styles.recommendedTitle}>
                  Strategy
                </div>
                <div className={styles.recommendedContent}>
                  {
                    boardStore.review.strategy
                  }
                </div>
                
                <div className={styles.recommendedTitle}>
                  Technique
                </div>
                <div className={styles.recommendedContent}>
                  {
                    boardStore.review.technique
                  }
                </div>
                
              </div>
              <div className={styles.recommendedDivider} />
              {
                isSelf ? <div className={styles.recommendedFooter}>
                  <div className={styles.footerLeft}></div>
                  <div className={styles.footerRight}>
                  <div className={styles.likeIcon}><img src={LikeActive}/></div>
                  <div className={styles.likeCount}>{boardStore.review.likes.length}</div>
                </div>
                </div> :
                <div className={styles.recommendedFooter}>
                <div className={styles.footerLeft}>
                  Do you think the recommended strategy and technique are useful? If so, you have one chance to give a “vote”.
                </div>
                <div className={styles.footerRight}>
                  <div className={styles.likeIcon} onClick={handleLike}><img src={likeStatus ? LikeActive : LikeInactive}/></div>
                  <div>+5</div>
                  <div className={styles.recommendedCoinIcon}><img src={Coin}/></div>
                </div>
              </div>
              }
              
            </div>
            <div className={styles.commentContainer}>
              <div className={styles.commentTitle}>
                <div className={styles.commentTitleLeft}>
                  <div className={styles.commentIcon}><img src={Comment}/></div>
                  Comments （{commentCount}）
                </div>
                <div className={styles.commentTitleRight}>
                  Tips：Visible after commenting
                </div>
              </div>
              {
                hasCommented || isSelf ? 
                <div className={styles.commentList}>
                  {
                    commentList && commentList.map((e, i) => {
                      const date = dayjs(e.createAt).format('YYYY/MM/DD')
                      return (
                      <div key={i} className={styles.commentItem}>
                        <div className={styles.commentItemtitle}>
                          <div className={styles.commentName}>{e.userName}</div>
                          <div className={styles.commentDate}>{date}</div>
                        </div>
                        <div className={styles.commentContent}>{e.content}</div>
                      </div>)
                    })
                  }
                </div> :
                <div className={styles.commentInputContainer}>
                  <textarea
                    className={styles.commentInput}
                    placeholder='(no less than 30 words)-Share your experiences regarding the recommended strategy and technique mentioned above.'
                    onChange={e => boardStore.comment = e.target.value}
                    value={boardStore.comment}
                  />
                  <div className={styles.commentInputBtn} onClick={submit}>
                    <div className={styles.submitBtn}>
                      <div>Submit</div>
                      <div className={styles.buttonDivider} />
                      +5
                      <div className={styles.buttonCoin}>
                        <img src={Coin} />
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </Spin>
    </div>
  )
}


export default observer(EditorDiscussion);