import React, { useEffect, useState } from 'react';
import { useDebounce } from 'react-use'
import { observer } from "mobx-react";
import styles from '../css/ReadingPage.module.scss'
import readingStore from '../store/readingStore'
import MediumEditor from 'medium-editor'
import TextArea from 'antd/es/input/TextArea';
import Bulb from '../img/bulb.svg'
import { countWords } from '../utils/utils';
import { Button, Modal } from 'antd';
import { useNavigate } from 'react-router';
import ReadingExercises from './ReadingExercises';
import userStore from '../store/userStore';
import ReadingSurvey from './ReadingSurvey';

const ReadingPage = ({}) => {

    useEffect(() => {
        if (readingStore.reading.article && !userStore?.profile?.isAdmin) {
            readingStore.startCount()
        }
        return () => readingStore.finishCount()
    }, [readingStore.reading.article, userStore?.profile?.isAdmin])

    const [modal, setOpen] = useState(false)

    const navigate = useNavigate()

    const words = countWords(readingStore.reading.note)

  useDebounce(() => {
    readingStore.updateReadingDetail({
      note: readingStore.reading.note
    })
  }, 2000, [readingStore.reading.note])

  if (!readingStore.loading && readingStore.reading.articleRichText) {

    const editor = new MediumEditor('#middle', {
      placeholder: false,
      // disableEditing: true,
      toolbar: {
        buttons: [
            {
              name: 'bold',
              action: 'bold',
              aria: 'highlight',
              tagNames: ['b'],
              contentDefault: '<b>H</b>',
              classList: ['custom-class-b'],
              attrs: {
                  'data-custom-attr': 'attr-value-b'
              }
            },
            'underline',
        ]
      },
    });

    editor.execAction()

    editor.subscribe('editableInput', function (event, editable) {
      event.preventDefault();
      readingStore.updateReadingDetail({articleRichText: editable.innerHTML})
    });

    const ele = document.getElementById('middle')

    if (ele) {

      editor.on(ele, 'keypress', function(e) {
        console.log(e)
        e.preventDefault();
      })

      editor.on(ele, 'keydown', function(e) {
        console.log(e)
        e.preventDefault();
      })
    }
  
  }

  const confirmSubmit = () => {
    // 出题

  }

  const handleSubmit = async() => {
    setOpen(false)
    await readingStore.submitReading()
    // navigate('/entrance')
  }

    return <div className={styles.main}>

          <div className={styles.middle} id="middle" dangerouslySetInnerHTML={{ __html: readingStore.reading.articleRichText }}>
          </div>
          <div className={styles.bottom}>
           
            <div className={styles.noteWrapper}>
                <div className={styles.title}>
                    <div>
                    <img src={Bulb} /> Notepad
                    </div>
                    {userStore?.profile?.isAdmin && <Button type='primary' onClick={() => {readingStore.showSurvey = true}}>Show Survey</Button>}
                </div>

                <TextArea rows={5} disabled={readingStore.reading.isCompleted} placeholder='Please write what you have learned from this text (no less than 10 words)' className={styles.note} defaultValue={readingStore.reading.note} onChange={e => readingStore.reading.note = e.target.value}>
                </TextArea>
                {
                    !readingStore.reading.isCompleted ? <Button disabled={words < 10} loading={readingStore.generateLoading} className={[styles.btn, words < 10 && styles.disable].join(' ')} onClick={readingStore.generateExercises}>Submit</Button> : 
<Button className={styles.btn} onClick={readingStore.generateExercises}>Show Exercises</Button>
                }
            </div>
          </div>

          <ReadingExercises show={readingStore.showExercises} />

          <ReadingSurvey show={readingStore.showSurvey} />

        {/* <Modal
          open={modal}
          centered
          closable={false}
          title="Attention"
          okText="Finish"
          cancelText="Not yet"
          onCancel={() => {
            setOpen(false)
          }}
          onOk={handleSubmit}
        >
          <div>Have you finished reading? If not, you can re-enter from reading history.</div>
        </Modal> */}
    </div>
}

export default observer(ReadingPage);