import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import styles from './css/History.module.scss'
import TabBar from './components/TabBar';
import Background from './components/Background';
import { getMyReadings } from './utils/request';
import historyLogo from './img/history-logo.svg'
import { countWords } from './utils/utils';
import { use } from 'echarts';

export default function History () {


  const [ loading, setLoading ] = useState(false)
  const [ data, setData ] = useState([])

  useEffect(() => {
    fetchData()
  }, [])


  const fetchData = async () => {
    setLoading(true)
    const data = await getMyReadings()
    setData(data.data)
    setLoading(false)
  }

  return (
    <div className={styles.page}>
      <TabBar customLogo={historyLogo} showHome/>
      <Background />

      <Spin spinning={loading}>
        <div className={styles.body}>
          <table  className={styles.table} rules="none" cellspacing="0" align='center' >
            <tr>  
              <th align="center">Number</th>   
              <th align="center">Title</th> 
              <th align="left">Words</th> 
              <th align="left">Status</th> 
              <th align="left">Operate</th> 
            </tr>
            {
              data.map((item, index) => {
                return (
                  <tr> 
                    <td align="center">{index + 1}</td>
                    <td align="left">{item.title || '-'}</td>
                    <td align="left">{countWords(item.article)}</td> 
                    <td align="left">{item.isCompleted ? 'complete' : <div className={styles.red}>uncomplete</div>}</td> 
                    <td align="left">{
                      item.isCompleted ? <a href={`/level/${item.id}`}>view</a> : <a href={`/level/${item.id}`}>continue</a>
                    }</td> 
                  </tr>
                )
              })
            }
            
          </table>
        </div>
      </Spin>
    </div>
  )
}