import React from 'react'
import styles from '../css/ReadMateRate.module.scss'

export default class ReadMateRate extends React.Component {

  constructor(props) {
    super(props)
  }

  handleClick = (score) => {
    const { onChange } = this.props
    onChange && onChange(score)
  }

  render() {
    const { score, step } = this.props
    const arr = Array.from({length: step}, (_, i) => i + 1);

    return (
      <div className={styles.group}>
        {
          arr.map(item => {
            return (
              <div className={[styles.item, item <= Number(score) ? styles.active : ''].join(' ')}>
                <div className={styles.score} onClick={() => { this.handleClick(item)}}>
                </div>
                <div className={styles.num}>
                  {item}
                </div>
              </div>
            )
          })
        }
       
      </div>
    )
  }
  
}
