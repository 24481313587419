import React from 'react'
import { observer } from 'mobx-react'
import styles from '../css/WeekSelector.module.scss'
import Date from '../img/date.png'
import DateArrow from '../img/date-arrow.png'
import CommentIcon from '../img/comment.png'
import planStore from '../store/planStore'

class WeekSeletor extends React.Component {

  constructor(props) {
    super(props)
  }


  render() {

    return (
      <div className={styles.group}>
        <div>
          <div className={styles.title}><img className={styles.date} src={Date}/>Start date</div>
          <div className={styles.dateTime}>{planStore.startDate}</div>
        </div>
        <img className={styles.dateArrow} src={DateArrow}/>
        <div>
          <div className={styles.title}><img className={styles.date} src={Date}/>End date</div>
          <div className={styles.dateTime}>{planStore.endDate}</div>
        </div>
      </div>
    )
  }
  
}

export default observer(WeekSeletor)