import React, { useState, useEffect, useRef } from 'react';
import { message, Modal, Spin, Table, Input } from 'antd';
import { observer } from "mobx-react";
import taskStore from './store/taskStore';
import ArticleConversation from './components/ArticleConversation';
import Selector from './components/Selector';
import styles from './css/ArticleDiscovery.module.scss';
import Notification from './img/notification.png'
import Arrow from './img/article-discovery-arrow.png'
import Close from './img/close-icon.png'
import { ArticleLength, GenreType, ReadingLevel, ReadingLevelExplanation } from './utils/constant';
import { useParams } from 'react-router';
import ReadSteps from './components/ReadSteps';
import userStore from './store/userStore';
const { TextArea } = Input;

function ArticleDiscovery(props) {

  const [modal, setOpen] = useState(false)
  const params = useParams()

  const showModal = () => {
    setOpen(true)
  }

  

  useEffect(() => {
    taskStore.getTaskDetail(params.id)
  }, [])

  const conversations = taskStore.task.aiArticles

  const messagesEndRef = useRef(null)

  const scrollToBottom = () => {
      messagesEndRef.current?.scrollTo({ behavior: "smooth", top: messagesEndRef.current.scrollHeight})
  }

  useEffect(() => {
      scrollToBottom()
  }, [conversations]);

  const columns = [
    {
      title: 'A1',
      dataIndex: 'A1',
      key: 'A1',
    },
    {
      title: 'A2',
      dataIndex: 'A2',
      key: 'A2',
    },
    {
      title: 'B1',
      dataIndex: 'B1',
      key: 'B1',
    },
    {
      title: 'B2',
      dataIndex: 'B2',
      key: 'B2',
    },
    {
      title: 'C1',
      dataIndex: 'C1',
      key: 'C1',
    },
    {
      title: 'C2',
      dataIndex: 'C2',
      key: 'C2',
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        Article Discovery
      </div>

      <ReadSteps current={0} />

      <div className={styles.wrapper}>
        <div className={styles.wrapperTitle}>
          Chat with Reade
        </div>
        <Spin spinning={taskStore.loading}>
          <div className={styles.wrapperContent} ref={messagesEndRef}>
            <ArticleConversation conversations={conversations} />
          </div>
        </Spin>
        <div className={styles.wrapperFooter}>
          <div className={styles.footerLeft}>
            <div className={styles.option}>
              <div className={styles.optionTitle}>1.The genre of article(types of text)I want to read</div>
              <a className={styles.optionIcon} href="https://www.notion.so/Type-of-Text-4218b572d11e44419e8a54c02cbf51f7?pvs=4" target="_blank">
                <img src={Notification} />
              </a>
              <div className={styles.optionSelector}>
                <Selector onChange={e => taskStore.genreType = e} width={160} options={GenreType.map(item => { return {value: item, label: item}})} />
              </div>
            </div>
            <div className={styles.option}>
              <div className={styles.optionTitle}>2.The reading level I intend to read</div>
              <div className={styles.optionIcon} onClick={showModal}>
                <img src={Notification} />
              </div>
              <div className={styles.optionContent}><Selector onChange={e => taskStore.readingLevel = e} width={160} options={ReadingLevel.map(item => { return {value: item, label: item}})} /></div>
            </div>
            <div className={styles.option}>
              <div className={styles.optionTitle}>3.The article length I want to read</div>
              <div className={styles.option3Content}><Selector onChange={e => taskStore.articleLength = e} width={160} options={ArticleLength.map(item => { return {value: item, label: item}})}/></div>
            </div>

            {
              userStore.profile && !!userStore.profile.hasInterest &&
              <div className={styles.option}>
                <div className={styles.optionTitle}>4.The topic of my personal interests</div>
                <div className={styles.optionInput}>
                  <TextArea 
                    placeholder="The topic could be a hobby or a life skill you have always wanted to develop (e.g., how to make a TikTok vlog) ; or information of importance to you, your family or your community (e.g., White people food）"
                    className={styles.textarea}
                    rows={4}
                    onChange={e => taskStore.interest = e.target.value}
                  />
                </div>
              </div>
            }

          </div>
          <div className={styles.footerRight} onClick={taskStore.discoverArticle}>
            <div className={styles.text}>
              <div>Article Discovery</div>
              <div>({taskStore.task.aiArticleLeft} times left)</div>
            </div>
            <div className={styles.icon}>
              <img src={Arrow} />
            </div>
          </div>
        </div>
      </div>

      <Modal
          open={modal}
          centered
          footer={null}
          maskClosable={true}
          closable={true}
          closeIcon={<div className={styles.modalClose}><img src={Close}/></div>}
          onCancel={() => setOpen(false)}
          width={"75vw"}
        >
          <div className={styles.modal}>
            <div className={styles.modalTitle}>Self-assessment grid on reading comprehension<a href="https://periwinkle-slash-7d2.notion.site/Examples-of-Reading-Level-b01502e846864f12b093a1ae34cbbf54?pvs=4" target="_blank">Examples of Reading Level</a></div>
            <Table
              columns={columns}
              dataSource={ReadingLevelExplanation}
              bordered
              pagination={false}
            />
            <div className={styles.modalFooter}>Source: The Common European Framework of Reference for Languages, 2023</div>
          </div>
        </Modal>

    </div>
  )
}


export default observer(ArticleDiscovery);