import React, { useState, useEffect } from 'react';
import { Spin, Space, Button, notification, Table, Modal } from 'antd';
import taskStore from './store/taskStore';
import styles from '../src/css/Main.module.scss'
import TabBar from './components/TabBar';
import ChartOne from './components/ChartOne';
import AddTask from './img/add-task.png'
import { getMyTask, getGraphData } from './utils/request';
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs';
import Complete from './img/complete.png'
import Uncomplete from './img/uncomplete.png'
import Selector from './components/Selector';
import Like from './img/vote-rank-like.png';
import Comment from './img/vote-rank-comment.png';
import View from './img/vote-rank-view.png';
import Commented from './img/commented-icon.png';
import boardStore from './store/boardStore';
import { observer } from "mobx-react";
import userStore from './store/userStore';
import ChartTwo from './components/ChartTwo';
import ChartThree from './components/ChartThree';
import planStore from './store/planStore';

const { confirm } = Modal;

function Main() {

  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const [api, contextHolder] = notification.useNotification();

  const options = boardStore.group.map(item => {
    return {
      label: `${item.challenge} (${item.count} Editor)`,
      value: item.challengeId,
    }              
  })

  const handleCreateTask = async () => {
    if (uncomplete > 0) {
      confirm({
        title: 'You have uncompleted tasks',
        content: 'Do you still want to create a new task?',
        onOk() {
          console.log('OK');
          continueCreate()
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    } else {
      continueCreate()
    }
  }

  const continueCreate = async() => {
    if (loading) {
      return false
    }
    setLoading(true)
    // 请求，跳discovery页面
    const result = await taskStore.createTask()
    setLoading(false)
    // console.log(result)
    if (result) {
      window.open(`/article-discovery/${result.data.id}`, '_blank')
    }
  }

  const handleLeft = (item) => {
    if(item.step === 0) {
      window.open(`/article-discovery/${item.id}`, '_blank')
    } else {
      window.open(`/explore/${item.id}`, '_blank')
    }
  }

  const handleRight = (item) => {
    if(item.step >= 2) {
      window.open(`/insightful-review/${item.id}`, '_blank')
    }
  }

  const [taskList, setTaskList] = useState([]);
  const [graphData, setGraphData] = useState({
    chartOne: undefined,
    chartTwo: undefined,
    chartThree: undefined,
  });

  const complete = taskList.filter(item => !!item.isCompleted).length
  const uncomplete = taskList.filter(item => !item.isCompleted).length

  useEffect(() => {
    planStore.fetchList()
    boardStore.getGroup()
    fetchTask()
  }, [])

  const openNotification = () => {
    const key = `open${Date.now()}`;
    const btn = (
      <Space>
        <Button type="primary" size="small" onClick={() => {
          api.destroy(key);
          navigate('/reflection-and-adjustment')
        }}>
          Reflection & Adjustment
        </Button>
      </Space>
    );
    api.open({
      message: 'Happy New Week!',
      description:
        'Please click on "Reflection & Adjustment" to reflect on your performance from last week and adjust your goals for the current week.',
      btn,
      key,
      duration: 0
    });
  }

  const fetchTask = async () => {
    const result = await getMyTask()
    setTaskList(result.data.tasks)

    const isNotificated = sessionStorage.getItem('isNotificated')
    if (result.data.isCompleted && !isNotificated) {
      openNotification()
      sessionStorage.setItem('isNotificated', true)
    }

    const data = await getGraphData()
    setGraphData(data.data)

  }
  
  const columns = [
    {
      title: 'Editor',
      dataIndex: 'editor',
      key: 'editor',
      render: (_, record) => {
        if (record.task?.user && record.task?.user?.username) {
          return <div>{record.task?.user?.username}</div>
        } 
        return null
      }
    },
    {
      title: 'Strategy',
      dataIndex: 'strategy',
      key: 'strategy',
      render: (_, record) => {
        return <a target="_blank" href={`/editor-discussion/${record.id}`} >{record.strategy}</a>
      }
    },
    {
      title: 'Votes Ranking',
      dataIndex: 'ranking',
      key: 'ranking',
      width: 250,
      render: (_, record) => {
        return <div className={styles.tableRanking}>
          <div className={styles.tableLike}>
            <div className={styles.tableLikeIcon}>
              <img src={Like} />
            </div>
            <div>{record.likes.length}</div>
          </div>
          <div className={styles.tableComment}>
            <div className={styles.tableCommentIcon}>
              <img src={Comment} />
            </div>
            <div>{record.comments.length}</div>
          </div>
          <div className={styles.tableView}>
            <div className={styles.tableViewIcon}>
              <img src={View} />
            </div>
            <div>{record.view}</div>
          </div>
        </div>
      }
    },
    {
      title: 'Commented',
      dataIndex: 'commented',
      key: 'commented',
      width: 200,
      render: (_, record) => {

        const comment = record.comments.find(item => item.uid === userStore.profile.id)

        if (comment)  {
          return <div className={styles.tableCommented}>
            <div className={styles.tableCommentedIcon}>
              <img src={Commented} />
            </div>
        </div>
        }
      }
    },
  ];


  return (
    <div className={styles.page}>
      <TabBar />
      {contextHolder}
      <div className={styles.wrapper}>
        <div className={styles.title}>Monitoring</div>
        <div className={styles.monitor}>
          <div className={styles.monitorWrapper}>
            <div className={styles.monitorTitle}>My and other editor’s reading article (number)</div>
            <div className={styles.graph}>
              <ChartOne data={graphData.chartOne}/>
            </div>
          </div>

          <div className={styles.monitorWrapper}>
            <div className={styles.monitorTitle}>My and other editor’s contribution for technique (number)</div>
            <div className={styles.graph}>
              <ChartTwo data={graphData.chartTwo}/>
            </div>
          </div>

          <div className={styles.monitorWrapper}>
            <div className={styles.monitorTitle}>Progress of strategy use</div>
            <div className={styles.graph}>
              <ChartThree data={graphData.chartThree}/>
            </div>
          </div>

        </div>

        <div className={styles.title}>My Task<span className={styles.subTitle}>(Completed {complete} / Uncompleted {uncomplete} )</span></div>
        <Spin spinning={boardStore.loading || loading}>
          <div className={styles.taskWrapper}>

                  <div className={styles.addTask} onClick={handleCreateTask}>
                    <img src={AddTask} className={styles.taskImg} />
                    <div>Add a new task</div>
                  </div>

                  {
                    taskList.map(item => {
                      return <div className={styles.taskDetail}>
                        <div className={styles.taskDate}>
                        {
                          dayjs(item.createdAt).format('YYYY-MM-DD')
                        }
                        </div>
                        <div className={styles.taskTitle}>
                          Title:
                        </div>
                        <div className={styles.titleContent}>{item.title || 'none'}</div>
                        
                        <div className={styles.btnWrapper}>
                          <div className={styles.leftBtn} onClick={() => handleLeft(item)}>Chat History</div>
                          <div className={[styles.rightBtn, item.step < 2 ? styles.disabled : ''].join(' ')} onClick={() => handleRight(item)}>Review</div>
                        </div>

                        <img src={item.isCompleted ? Complete : Uncomplete} className={styles.completeIcon}/>
                      </div>
                    })
                  }
          </div>
        </Spin>

        <div className={styles.editorialTitle}>Editorial Board</div>
        <div className={styles.editorialWrapper}>
          <div className={styles.editorSelector}>
            <Selector
              options={options}
              value={boardStore.selectedChallengeId}
              onChange={e => boardStore.changeSelected(e)}
              width={315}
            />
          </div>
          <div className={styles.editorTable}>
            <div className={styles.editorBanner} />
            <div className={styles.editorTable}>
              <Table
                loading={boardStore.loading}
                columns={columns}
                dataSource={boardStore.list}
                bordered
                pagination={false}
              />
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default observer(Main);