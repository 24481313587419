import React, { useState, useEffect } from 'react';
import { Spin, Space, Button, notification, Table } from 'antd';
import styles from './css/Achievement.module.scss'
import TabBar from './components/TabBar';
import Background from './components/Background';
import Coin from './img/coin.png'
import { getLeaderBoard } from './utils/request';
import Deco from './img/deco2.png'

import achievementLogo from './img/achievement-logo.svg'

export default class Achievement extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      data: [],
    }
    this.fetchData()
  }

  fetchData = async () => {
    const data = await getLeaderBoard()
    this.setState({
      data: data.data,
    })
  }

  render() {

    const content = [
      {
        describe: 'Complete a learning task',
        coin: 20,
      }, {
        describe: 'Answer one multiple-choice question correctly',
        coin: 10
      }
    ]

    const Rankings = () => {
      const { loading, data } = this.state 
      return (
        <Spin spinning={loading} style={{marginBottom: 20}}>
          <div className={styles.items}>
          {
            data.map((item, i) => {
              return  <div className={styles.rankItem} key={i}>
                
                {
                  i < 3 ? <img src={require(`./img/Level${i+1}.png`)} className={styles.rankImg}/> :  <div className={styles.rankIndex}>{i+1}</div> 
                }

                <div className={styles.rankName}>{item.username}</div>

                <div className={styles.rankCoin}>  {item.coins} <img src={Coin} className={styles.leftCoin}/></div>

              </div>
            })
          }
          </div>
        </Spin>
      )
    }

    return (
    <div className={styles.page}>
      <TabBar customLogo={achievementLogo} showHome/>
      <Background />

      <div className={styles.body}>
        <div className={styles.left}>
          <div className={styles.title}>Leaderboard</div>
          <div className={styles.leftTitle}>How to gain coins?</div>
            <div className={styles.leftContent}>
              {content.map((content, index) => {
                const hasDetail = content.details && content.details.length > 0
                return(
                  <div  className={hasDetail ? styles.hasDetail : styles.noDetail} key={index}>
                      <div className={styles.detailTitle}>{content.describe}</div>
                      {
                        hasDetail ?
                        content.details.map((detail, index) =>
                        <div className={styles.detail} key={index}>
                          {detail.describe}
                          <img src={Coin} className={styles.leftCoin}/>
                          <div className={styles.leftCoinNums}>+{detail.coin}</div>
                        </div>
                        ) :
                        <div className={styles.detail} key={index}>
                          <img src={Coin} className={styles.leftCoin}/>
                          <div className={styles.leftCoinNums}>+{content.coin}</div>
                        </div>
                      }
                  </div>
                  )}
              )}
            </div>
        </div>
        <div className={styles.right}>
          <div className={styles.rightTitle}>The diligent reader</div>
          <img src={Deco} className={styles.deco} />
          <div className={styles.tabs}>
            <div>Ranking</div>
            <div>Name</div>
            <div>Coin</div>
          </div>
          <div className={styles.rightContent}>
            <Rankings />
          </div>
        </div>
      </div>
    
    </div>
    )

  }
}