export const GenreType = ['Explanation', 'Narrative', 'Discussion', 'Argumentative', 'Report', 'Instruction', 'Recount', 'Descriptive']
export const ReadingLevel = ['A1', 'A2', 'B1', 'B2', 'C1', 'C2']
export const ArticleLength = ['500-1000', '1000-1500']

export const ReadingLength = ['300-500', '500-1000', '1000-1500']

export const ReadingInterest = ['Science', 'Culture, Arts and Music', 'History', 'Health and Medicine', 'Technology', 'Business and Economics', 'Education', 'Biology', 'English Language and Literature', 'Society', 'Physics', 'Engineering', 'Politics', 'Psychology', 'Mathematics', 'Chemistry', 'Geography, Agriculture and Enviroment', 'Architecture, Planning and Design', 'Law', 'Journalism']

export const ExercisesOptions = ['A', 'B', 'C', 'D']

export const ReadingLevelExplanation = [
    {
      key: '1',
      A1: 'I can understand familiar names, words/signs and very simple sentences, for example on notices and posters or in catalogues. ',
      A2: 'I can read very short, simple texts. I can find specific, predictable information in simple everyday material such as advertisements, prospectuses, menus and timetable’s and I can understand short simple personal letters.',
      B1: 'I can understand texts that consist mainly of high frequency everyday or job-related language. I can understand the description of events, feelings and wishes in personal letters. ',
      B2: 'I can read articles and reports concerned with contemporary problems in which the writers adopt particular stances or viewpoints. I can understand contemporary literary prose. ',
      C1: 'I can understand long and complex factual and literary texts, appreciating distinctions of style. I can understand specialised articles and longer technical instructions, even when they do not relate to my field.',
      C2: 'I can read with ease virtually all forms of the written/signed language, including abstract, structurally or linguistically complex text’s such as manuals, specialised articles and literary works. ',
    }
];

export const ReadingLevelExampleSource = [{
    key: '1',
    1: 'I can understand familiar names, words/signs and very simple sentences, for example on notices and posters or in catalogues. ',
    2: 'I can read very short, simple texts. I can find specific, predictable information in simple everyday material such as advertisements, prospectuses, menus and timetable’s and I can understand short simple personal letters.',
    3: 'I can understand texts that consist mainly of high frequency everyday or job-related language. I can understand the description of events, feelings and wishes in personal letters. ',
    4: 'I can read articles and reports concerned with contemporary problems in which the writers adopt particular stances or viewpoints. I can understand contemporary literary prose. ',
    5: 'I can understand long and complex factual and literary texts, appreciating distinctions of style. I can understand specialised articles and longer technical instructions, even when they do not relate to my field.',
    6: 'I can read with ease virtually all forms of the written/signed language, including abstract, structurally or linguistically complex text’s such as manuals, specialised articles and literary works. ',
}]


export const ReadingLevelExample = [
    {
        level: 1,
        example: 'I’m having a wonderful time in France. My course is great and I’m enjoying learning French. Paris is a very old and interesting city. There are lots of beautiful old buildings to visit. I am staying with a French family. They are very nice and I feel very welcome in their house.'
    },
    {
        level: 2,
        example: 'Yang Jing usually plays her music in big halls in places like New York and Paris. Yang plays an old Chinese musical instrument called a pipa. She has been famous in China since 1986. Now she is 34 years old and the best pipa player in the world.'
    },
    {
        level: 3,
        example: 'Country Delights serves interesting vegetarian dishes, using the freshest vegetables in a seasonally changing menu. Prices are higher than average but it’s worth the extra for the good service and relaxed atmosphere. It’s open for lunch only, as it’s used for private parties in the evening.'
    },
    {
        level: 4,
        example: 'Since she took up her post a year ago as PA to the director of the Natural History Museum, Dr. Neil Chalmers, Anna Cuss has become a source of knowledge on all manner of museum issues – from botany and zoology to fund-raising, re-roofing, and heating systems. '
    },
    {
        level: 5,
        example: 'When I open the pages of Signals for Survival by Niko Tinbergen, I can hear the long calls of herring gulls, recall the smell of the guano in the hot sun and visualize the general hullabaloo of the colony. This book explains superbly, through words and pictures, the fascinating world of animal communication.'
    },
    {
        level: 6,
        example: 'If you work for an advertising agency, the early years of the 1990s may well have been the toughest of your professional life. The recession in business was bad enough. It was longer, deeper, and more severe than anticipated by even the most pessimistic, hitting industrialized nations as hard as anything else for thirty years.'
    },
]
