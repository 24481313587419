import React, { useState, useEffect } from 'react';
import { Table, Form, Button } from 'antd';
import { getUserDetailReading } from './utils/request';

import TabBar from './components/TabBar';
import styles from '../src/css/ViewUserDetail.module.scss'
import { useLocation } from 'react-router';
import Loading from './components/Loading';
import dayjs from 'dayjs';

function ViewUserDetail(props) {

  const [userData, setUserData] = useState()
  const [loading, setLoading] = useState(false)

  const gotoDetail = (id) => {
    // navigate('/view-user-detail', { state: { id } })
    window.open(`/level/${id}`)
  }

  // const state = useLocation().state
  const id = window.location.search.substring(1).split('=')[1]

  const getDeatal = async() => {
    setLoading(true)
    const userDetail = await getUserDetailReading({id: id})
    if (userDetail) {
      setUserData(userDetail.data)
    }
    setLoading(false)
  }

  useEffect(() => {
    getDeatal()
  }, [])


  const taskColumns = [
    {
      title: 'Article Title',
      dataIndex: 'title',
      width: 500,
      render: (_, record) => (record.title || 'none')
    },
    {
      title: 'Reading Time',
      dataIndex: 'readingSeconds',
      render: (_, record) => (record.readingSeconds > 60 ? Math.ceil(record.readingSeconds / 60) + ' mins' : record.readingSeconds + ' secs')
    },
    {
      title: 'Exercise',
      dataIndex: 'exercises',
      render: (_, record) => {
        const correct = record.exercises.filter(item => item.isCorrect).length
        if(record.exercises.length === 0 || record.exercises[0].answer === null) return '-'
        return `${correct}/${record.exercises.length}`
      }
    },
    {
      title: 'Reading Create At',
      dataIndex: 'createAt',
      render: (_, record) => dayjs(record.createAt).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      key: 'operation',
      width: 200,
      fixed: 'right',
      render: (_, record) => {
        return  <Button size='small' type='primary' onClick={() => gotoDetail(record.id)}>Detail</Button>
      },
    },
  ];


  if (!userData) {
    return <Loading />
  }
  

  return (
    <div>
      {/* <TabBar /> */}
      <div className={styles.page}>
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
        >
          <Form.Item>
            User name: {userData.username}
          </Form.Item>
          <Form.Item>
            Gender: {userData.gender === 'M' ? 'Male' : 'Femail'}
          </Form.Item>
          <Form.Item>
            Class: {userData.class ? userData.class : 'None'}
          </Form.Item>
          <Form.Item>
            Email: {userData.email}
          </Form.Item>
        </Form>
        
        <div className={styles.ideas}>
          <div className={styles.title}>Readings:</div>
          <Table
            className={styles.table}
            columns={taskColumns}
            dataSource={userData.readingTasks}
            sticky
            bordered
            loading={loading}
          />
        </div>


      </div>
    </div>
  )

  
}


export default ViewUserDetail;