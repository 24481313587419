import React, { useState, useEffect } from 'react';
import { message, Modal, Table, Radio, RadioChangeEvent, Button } from 'antd';
import { observer } from "mobx-react";
import styles from '../css/Exercises.module.scss';
import taskStore from '../store/taskStore';
import { useNavigate } from 'react-router';
import { ExercisesOptions } from '../utils/constant';
import userStore from '../store/userStore';

function Exercises(props) {

  const { show } = props

  const navigate = useNavigate()

  const submit = async () => {
    taskStore.submitExercises()
  }

  const handleContinue = async () => {
    // 跳转到下一个页面
    taskStore.showExercises = false
    if (userStore.profile.hasSurvey && !taskStore.task.survey) {
      taskStore.showSurvey = true
    } else {
      navigate(`/insightful-review/${taskStore.task.id}`)
    }
  }

  return (
      <Modal
        open={show}
        centered
        closeIcon={<div className={styles.closeIcon}>Back to reading</div>}
        footer={null}
        width={"60vw"}
        onCancel={() => taskStore.showExercises = false}
      >
        <div className={styles.modal}>
          <div className={styles.modalTitle}>Reading Comprehension Exercise</div>
          <div className={styles.modalContent}>

            {
              taskStore.task.exercises && taskStore.task.exercises.map((item, index) => {
                return <>
                <div className={styles.modalQuestion}>
                  {index + 1}. {item.question}
                </div>
                <div className={styles.modalAnswerRate}>
                  <Radio.Group disabled={taskStore.showAnswer} onChange={e => taskStore.exerciseAnswers[index] = e.target.value} value={taskStore.exerciseAnswers[index]}>
                    {
                      item.options &&
                      item.options.map((e, index) => 
                        <Radio value={index}>{ExercisesOptions[index]}. {e}</Radio>
                      )
                    }
                  </Radio.Group>
                  {
                    taskStore.showAnswer && <div className={styles.correctAnswer}>
                      <div className={[styles.modalAnswer, item.correctAnswer === taskStore.exerciseAnswers[index] ? styles.green : styles.red].join(' ')}>
                        Correct Answer: {ExercisesOptions[item.correctAnswer]}
                      </div>
                      <div className={[styles.modalAnswer, item.correctAnswer === taskStore.exerciseAnswers[index] ? styles.green : styles.red].join(' ')}>
                        Explanation: {item.explanation}
                      </div>
                    </div>
                  }
                </div>
                </>
              })
            }
            
          </div>

          {
            taskStore.showAnswer ? 
            <div className={styles.modalBottom}>
              <Button onClick={handleContinue} type="primary" size="large" loading={taskStore.submitLoading} className={styles.submitBtn}>Next</Button>
            </div> :
            <div className={styles.modalBottom}>
              <Button onClick={submit} type="primary" size="large" loading={taskStore.submitLoading} disabled={taskStore.exerciseAnswers?.length !== taskStore.task?.exercises?.length} className={styles.submitBtn}>Answer</Button>
            </div>
          }
         
        </div>
      </Modal>
  )
}


export default observer(Exercises);