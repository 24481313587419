import React, { useState, useEffect } from 'react';
import { Spin, notification, Table } from 'antd';
import styles from '../src/css/Entrance.module.scss'
import TabBar from './components/TabBar';
import Background from './components/Background';
import { useNavigate } from 'react-router-dom'
import { observer } from "mobx-react";
import EntryCard from './components/EntryCard';
import readingStore from './store/readingStore';
import { getMyReadings } from './utils/request';
import { set } from 'mobx';

function Entrance() {

  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [uncompleteCount, setUncompleteCount] = useState(0)

  useEffect(() => {
    fetchReadingCount()
  }, [])

  const fetchReadingCount = async () => {
    const data = await getMyReadings()
    const count = data.data.filter(item => !item.isCompleted).length
    setUncompleteCount(count)
  }

  const [api, contextHolder] = notification.useNotification();

  const handleClick = async (title) => {
    if (title === 'reading') {
      if (loading) {
        return false
      }
      setLoading(true)
      // 请求，跳discovery页面
      const result = await readingStore.createReading()
      setLoading(false)
      // console.log(result)
      if (result) {
        navigate(`/level/${result.data.id}`, '_blank')
      }
    } else if (title === 'history') {
      navigate('/history')
    } else if (title === 'achievement') {
      navigate('/achievement')
    }
  }

  return (
    <div className={styles.page}>
      <TabBar />
      {contextHolder}

      <Background showSlogan/>
      <Spin spinning={readingStore.loading}>
        <div className={styles.card}>
          <EntryCard title="Reading" disabled={readingStore.loading} handleJump={() => handleClick('reading')} />
          <EntryCard title="History" disabled={readingStore.loading} handleJump={() => handleClick('history')} bubble={uncompleteCount > 0 && `${uncompleteCount} Uncomplete`} />
          <EntryCard title="Achievement" disabled={readingStore.loading} handleJump={() => handleClick('achievement')} />
        </div>
      </Spin>
     
    </div>
  )
}

export default observer(Entrance);