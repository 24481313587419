import React, { useState, useEffect } from 'react';
import { message, Modal, Table, Radio, RadioChangeEvent, Button } from 'antd';
import { observer } from "mobx-react";
import dayjs from 'dayjs'
import styles from './css/ReflectionAndAdjustment.module.scss';
import Plan from './img/weekly-plan.png'
import Achievement from './img/weekly-achievement.png'
import PlanForNextWeek from './img/plan-for-next-week.png'
import Default from './img/weekly-achievement-default.png'
import { getPlanList } from './utils/request'
import { useNavigate } from 'react-router';

function ReflectionAndAdjustment(props) {
  const navigate = useNavigate()

  const [planList, setPlanList] = useState([])

  const handleCreate = () => {
    navigate('/create-plan')
  }

  useEffect(() => {
     fetchPlanList()
  }, [])

  const fetchPlanList = async () => {
    const result = await getPlanList()
    setPlanList(result.data)
  }

 
  return (
    <div className={styles.container}>
      <div className={styles.title}>Reflection & Adjustment</div>
      <div className={styles.displayContainer}>
        {
          planList[0] && planList[0].isCompleted ? <div className={styles.planButtonContainer}>
            <div className={styles.planButton} onClick={handleCreate}>
              <div className={styles.planButtonIcon}><img src={PlanForNextWeek} /></div>
              Planning for Current Week
            </div>
          </div> : <div />
        }
        {
          planList && planList.map((e, i) => {
            const startDate = dayjs(e.startDate).format('YYYY/MM/DD')
            const endDate = dayjs(e.endDate).format('YYYY/MM/DD')
            return (
              <div className={styles.weeklyContainer}>
                <div className={styles.weeklyTable} key={i}>
                  <div className={styles.plan}>
                    <div className={styles.planTitle}>
                      <div className={styles.planTitleIcon}><img src={Plan} /></div>
                      Weekly Planning
                    </div>
                    <div className={styles.planContent}>
                      <div className={styles.planItem}>
                        <div>Start date: {startDate}</div>
                        <div>End date: {endDate}</div>
                      </div>
                      <div className={styles.planItem}>The number of articles I plan to read: {e.planToRead}</div>
                      <div className={styles.planItem}>Frequency: {e.frequency}</div>
                      <div className={styles.planItem}>Value for each {e.frequency === 'daily' ? 'day' : 'week'}: {e.planReadMins} minutes</div>
                      <div className={styles.planItem}>
                        <div className={styles.planSubTitle}>challenges:</div>
                        {
                          e.goatStrategies.map((strategy, i) => {
                            return (
                              <div className={styles.planTextContent} key={i}>{strategy.challenge}</div>
                            )
                          })
                        }
                      </div>
                      <div className={styles.planItem}>
                        <div className={styles.planSubTitle}>Planning strategies:</div>
                        {
                          e.goatStrategies.map((strategy, i) => {
                            return (
                              <div className={styles.planTextContent} key={i}>{i+1}. {strategy.strategy}</div>
                            )
                          })
                        }
                      </div>
                    </div>
                  </div>
                  <div className={styles.divider} />
                  <div className={styles.achievement}>
                    <div className={styles.achievementTitle}>
                        <div className={styles.achievementTitleIcon}><img src={Achievement} /></div>
                        Weekly Achievement
                    </div>
                    <div className={styles.achievementContent}>
                      {
                        e.achievement ?
                        <div className={styles.showContent}>
                          <div className={styles.achievementItem}>
                            The number of articles I have read: {e.achievement.readArticle}
                          </div>
                          <div className={styles.achievementItem}>
                            Average time per {e.frequency === 'daily' ? 'day' : 'week'}: {e.achievement.avgReadMins} minutes
                          </div>
                          <div className={styles.achievementItem}>
                            <div className={styles.planSubTitle}>Overcome Challenges:</div>
                            {
                              e.achievement.overcomeChallenges.map((e, i) => {
                                return (
                                  <div className={styles.achievementTextContent} key={i}>{e.challenge}</div>
                                )
                              })
                            }
                          </div>
                          <div className={styles.achievementItem}>
                          <div className={styles.planSubTitle}> Frequency of strategies used: </div>
                            {
                              e.achievement.usedStrategies.map((e, i) => {
                                return (
                                  <div className={styles.achievementTextContent} key={i}>{e.strategy}（{e.usedTimes} times ）</div>
                                )
                              })
                            }
                          </div>
                        </div> :
                        <div className={styles.defaultPage}>
                          <div className={styles.defaultIcon}><img src={Default}/></div>
                          Only be available after the end of your designated reading week
                        </div>
                      }
                    </div>
                  </div>
                </div>

                <div className={styles.footer}>
                  created at {dayjs(e.createAt).format('YYYY-MM-DD')}
                </div>
              </div>
              )
          })
        }
        
      </div>
      
    </div>
  )
}


export default observer(ReflectionAndAdjustment);