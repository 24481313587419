import React from 'react';
import styles from '../css/LevelCard.module.scss';
import history from '../img/history-ac.svg'
import achievement from '../img/achievement-ac.svg'
import reading from '../img/reading-ac.svg'
import LevelTag from '../img/level-tag.svg'
 
const getIcon = (title) => {
    switch (title) {
        case 'History':
            return history;
        case 'Achievement':
            return achievement;
        case 'Reading':
            return reading;
        default:
            return history;
    }

}

const LevelCard = (props) => {

    const { level, example, handleClick, isActive } = props;

    return <div className={[styles.main, isActive && styles.mainAc].join(' ')} onClick={handleClick}>
        <div className={styles.wrapper} >
            <img src={LevelTag}/>
            <div className={styles.tag}>{level} Example</div>
        </div>
        <div className={styles.example}>
            {example}
        </div>
    </div>
}

export default LevelCard;