import React from 'react'
import * as echarts from 'echarts';
import styles from '../css/Chart.module.css'
import dayjs from 'dayjs';

export default class ChartOne extends React.Component {

  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.renderChat()
  }

  componentDidUpdate() {
    this.renderChat()
  }

  renderChat() {
    const { data } = this.props
    if (!data) return false

    const { avg, mine } = data

    // const realData = avg.map(item => {

    //   const startDate = dayjs(item.startDate).format('YYYY-MM-DD')
    //   const endDate = dayjs(item.endDate).format('YYYY-MM-DD')
    //   console.log(startDate, endDate)

    //   const mineItem = mine.find(i => i.startDate === startDate)

    //   return {
    //     startDate,
    //     endDate,
    //     tag: dayjs(item.startDate).format('MM/DD') + '-' + dayjs(item.endDate).format('MM/DD'),
    //     avgReadingArticle: item.readArticle,
    //     myReadingArticle: mineItem ? mineItem.readArticle : 0,
    //     myReadingTarget: mineItem ? mineItem.planToRead : 0,
    //   }
    // })

    const realData = mine.map(item => {
      const startDate = dayjs(item.startDate).format('YYYY-MM-DD')
      const endDate = dayjs(item.endDate).format('YYYY-MM-DD')

      const avgItem = avg.find(i => i.startDate === item.startDate)

      return {
        startDate,
        endDate,
        tag: dayjs(item.startDate).format('MM/DD') + '-' + dayjs(item.endDate).format('MM/DD'),
        avgReadingArticle: avgItem ? avgItem.readArticle : undefined,
        myReadingArticle: Number(item.count),
        myReadingTarget: item.planToRead,
      }
    })


    const chartDom = document.getElementById('ChartOne');
    const myChart = echarts.init(chartDom);
    const option = {
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        bottom: '0px',
      },
      grid: {
        height: '50%',
      },
      xAxis: [
        {
          type: 'category',
          data: realData.map(item => item.tag),
          axisPointer: {
            type: 'shadow'
          }
        }
      ],
      yAxis: [
        {
          type: 'value',
          name: 'number',
        },
      ],
      series: [
        {
          name: 'My reading article',
          type: 'bar',
          data: realData.map(item => item.myReadingArticle)
        },
        {
          name: 'Average reading article',
          type: 'line',
          data: realData.map(item => item.avgReadingArticle)
        },
        {
          name: 'Target reading article',
          type: 'line',
          data: realData.map(item => item.myReadingTarget)
        }
      ]
    };

    myChart.setOption(option);
  }

  render() {

    return (
      <div id="ChartOne" className={styles.chartOne}>
      </div>
    )
  }
  
}
