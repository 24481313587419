import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Button, message } from 'antd';
import TabBar from './components/TabBar';
import styles from './css/CreatePlan.module.scss';
import WeekSeletor from './components/WeekSelector';
import Selector from './components/Selector';
import FrequencyBox from './components/FrequencyBox';
import GoatList from './components/GoatList';
import planStore from './store/planStore';
import { useNavigate } from 'react-router';


function CreatePlan(props) {


  const navigate = useNavigate()
  const options = [...new Array(99).keys()].map(item => {
    return {
      value: item + 1,
      label: item + 1
    }
  })

  const submit = async () => {
    const result = await planStore.submit()
    if (result) {
      message.success('Submit successfully')
      navigate('/main', { replace: true })
    }
  }

  useEffect(() => {
    planStore.fetchList()
  }, [])


  return (
    <div>
      <TabBar title="Goal Pursuit" hideInfo/>

      <div className={styles.wrapper}>

        <div className={styles.content}>

          <div className={styles.left}>

            <div className={styles.title}>Add Weekly Plan</div>

            <WeekSeletor />


            <div className={styles.planRead}>
              <div>The number of articles I plan to read</div>
              <Selector width={160} options={options} onChange={e => planStore.planToRead = e} />
            </div>

            <div className={styles.title}>Frequency</div>

            <div className={styles.frequency}>
              <FrequencyBox title="Daily" selected={planStore.frequency === 'daily'} onSelect={() => planStore.frequency = 'daily'} onChange={e => planStore.planReadMins = e } subTitle="Value for each day" />
              <FrequencyBox title="Weekly" selected={planStore.frequency === 'weekly'} onSelect={() => planStore.frequency = 'weekly'} onChange={e => planStore.planReadMins = e} subTitle="Value for each week" />
            </div>

          </div>

          <div className={styles.right}>
            <div className={styles.title}>Pursue Weekly Goals</div>

            <GoatList/>

          </div>
        </div>
      </div>

      <div className={styles.bottom}><Button loading={planStore.submitLoading} onClick={submit} type="primary" size="large" style={{width: "200px"}}> Submit </Button></div>

    </div>
  )
}


export default observer(CreatePlan);