import React, { useState, useEffect } from 'react';
import { message, Modal, Table, Radio, RadioChangeEvent, Button } from 'antd';
import { observer } from "mobx-react";

import styles from '../css/Survey.module.scss';
import ReadMateRate from '../components/ReadMateRate';
import taskStore from '../store/taskStore';
import { useNavigate } from 'react-router';
import userStore from '../store/userStore';

function Survey(props) {

  const { show } = props

  const navigate = useNavigate()

  const submit = async () => {
    if(taskStore.survey.interesting === 0 || taskStore.survey.enjoy === 0) {
      message.error('Please finish all the questions')
      return
    }
    await taskStore.submitSurvey()
    taskStore.showSurvey = false
    // 跳转到下一个页面
    navigate(`/insightful-review/${taskStore.task.id}`)
  }


  return (
      <Modal
        open={show}
        centered
        footer={null}
        maskClosable={userStore?.profile?.isAdmin}
        closable={userStore?.profile?.isAdmin}
        width={"60vw"}
        moveable
        onCancel={() => taskStore.showSurvey = false}
      >
        <div className={styles.modal}>
          <div className={styles.modalTitle}>Self-reflection</div>
          <div className={styles.modalSubTitle}>(Please fill in this online survey)</div>

          <div className={styles.hint}>
            <div className={styles.first}>Direction: Please select the response that best represents your perception</div>
            <div className={styles.second}>1 = Not at all; 2 = Slightly; 3 = Somewhat; 4 = Moderately; 5 = Very</div>
          </div>

          <div className={styles.modalContent}>
            <div className={styles.modalQuestion}>
            1. Do you think this article is interesting? Please rate the level of interest in this particular text.
            </div>
            <div className={styles.modalAnswerRate}>
              <div className={styles.modalRate}>
                <ReadMateRate step={5} score={taskStore.survey.interesting} onChange={e => taskStore.survey.interesting = e} />
              </div>
            </div>
            <div className={styles.modalQuestion}>
            2. How much do you enjoy the reading process?
            </div>
            <div className={styles.modalAnswerRate}>
              <div className={styles.modalRate}>
                <ReadMateRate step={5} score={taskStore.survey.enjoy} onChange={e => taskStore.survey.enjoy = e} />
              </div>
            </div>

          </div>
          <div className={styles.modalBottom}>
            <Button onClick={submit} disabled={userStore?.profile?.isAdmin} type="primary" size="large" loading={taskStore.submitLoading} className={styles.submitBtn}>Submit</Button>
          </div>
        </div>
      </Modal>
  )
}


export default observer(Survey);