import React from 'react'
import { Steps } from 'antd'
import styles from '../css/ReadSteps.module.css'
import { useNavigate } from 'react-router'
import taskStore from '../store/taskStore'

export default function ReadSteps (props) {
  const navigate = useNavigate()

  const handleChange = (index) => {
    // !disabled && onChange && onChange(score)
    console.log(index)
    if (index === 0) {
      navigate(`/article-discovery/${taskStore.task.id}`, { replace: true })
    }
    if (index === 1 && taskStore.task.step >= 1) {
      navigate(`/explore/${taskStore.task.id}`, { replace: true })
    }
    if (index === 2 && taskStore.task.step >= 2) {
      navigate(`/insightful-review/${taskStore.task.id}`, { replace: true })
    }
  }

  const { current } = props

  return (
    <div className={styles.steps} >
      <Steps
        current={current}
        onChange={handleChange}
        className={styles.step}
        items={[
          {
            title: 'Article Discovery',
          },
          {
            title: 'Strategy Exploration',
          },
          {
            title: 'Insightful Review',
          },
        ]}
      />
    </div>
  )
  
}
