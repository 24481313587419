import React from 'react'
import * as echarts from 'echarts';
import styles from '../css/Chart.module.css'

export default class ChartTwo extends React.Component {

  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.renderChat()
  }

  componentDidUpdate() {
    this.renderChat()
  }

  renderChat() {
    const {data} = this.props
    if (!data) return

    const chartDom = document.getElementById('ChartTwo');
    const myChart = echarts.init(chartDom);
    const option = {
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        bottom: '0px'
      },
      grid: {
        height: '50%',
      },
      xAxis: [
        {
          type: 'category',
          // prettier-ignore
          data: ['Motivation', 'Behavior', 'Cognition'],
          axisLabel: {
            show: true,
            interval: 0,
          }
        }
      ],
      yAxis: [
        {
          type: 'value'
        }
      ],
      series: [
        {
          name: 'My recommended technique',
          type: 'bar',
          data: data.mine,
        },
        {
          name: 'Average recommended technique',
          type: 'bar',
          data: data.avg.map(item => Number(item)),
        }
      ]
    };

    myChart.setOption(option);
  }

  render() {
    return (
      <div id="ChartTwo" className={styles.chartOne}>
      </div>
    )
  }
  
}
