import React, { useState, useEffect } from 'react';
import { observer } from "mobx-react";
import styles from '../css/ChooseLevel.module.scss';
import { ReadingLevelExample } from '../utils/constant';
import LevelCard from './LevelCard';
import readingStore from '../store/readingStore';
import { Button, Spin, Modal, Table } from 'antd';
import Bulb from '../img/bulb.svg';
import Close from '../img/close-icon.png'
import { ReadingLevelExampleSource } from '../utils/constant';

const ChooseLevel = () => {

    const [modal, setOpen] = useState(false)
    const showModal = () => {
        setOpen(true)
    }
    
    const columns = [
        {
          title: '1',
          dataIndex: '1',
          key: '1',
        },
        {
          title: '2',
          dataIndex: '2',
          key: '2',
        },
        {
          title: '3',
          dataIndex: '3',
          key: '3',
        },
        {
          title: '4',
          dataIndex: '4',
          key: '4',
        },
        {
          title: '5',
          dataIndex: '5',
          key: '5',
        },
        {
          title: '6',
          dataIndex: '6',
          key: '6',
        },
      ];

    return (
        <Spin spinning={readingStore.loading}>
            <div>
                <div className={styles.cardWrapper}>
                    {
                        ReadingLevelExample.map(item => {
                            return (
                                <LevelCard level={item.level} example={item.example} isActive={readingStore.reading.readingLevel === item.level} handleClick={() => {
                                    readingStore.reading.readingLevel = item.level
                                }}/>
                            )
                        })
                    }
                </div>

                <div className={styles.btnWrapper}>
                    <Button disabled={readingStore.reading.readingLevel == null} onClick={() => readingStore.submitLevel()} className={[styles.btn, readingStore.reading.readingLevel === null && styles.disable].join(' ')}>Choose</Button>
                </div>

                <div className={styles.assessment} onClick={showModal}>
                    <img src={Bulb}/>
                    <div className={styles.content}>Self-assessment</div>
                </div>
                

                <Modal
                    open={modal}
                    centered
                    footer={null}
                    maskClosable={true}
                    closable={true}
                    closeIcon={<div className={styles.modalClose}><img src={Close}/></div>}
                    onCancel={() => setOpen(false)}
                    width={"80vw"}
                    >
                    <div className={styles.modal}>
                        <div className={styles.modalTitle}>Self-assessment grid from CEFR</div>
                        <div className={styles.modalSubTitle}>Understanding your reading level</div>
                        <Table
                        columns={columns}
                        dataSource={ReadingLevelExampleSource}
                        bordered
                        pagination={false}
                        />
                        <div className={styles.modalFooter}>Source: The Common European Framework of Reference for Languages, 2023</div>
                    </div>
                </Modal>

            </div>
        </Spin>
    )
}

export default observer(ChooseLevel)