import React, { useState, useEffect } from 'react';
import { Spin, Space, Button, notification, Table } from 'antd';
import styles from '../src/css/Main.module.scss'
import TabBar from './components/TabBar';
import Selector from './components/Selector';
import Like from './img/vote-rank-like.png';
import Comment from './img/vote-rank-comment.png';
import View from './img/vote-rank-view.png';
import Commented from './img/commented-icon.png';
import boardStore from './store/boardStore';
import { observer } from "mobx-react";
import userStore from './store/userStore';
import planStore from './store/planStore';
import ChartOne from './components/ChartOne';
import { getGraphData } from './utils/request';
import ChartTwo from './components/ChartTwo';
import ChartThree from './components/ChartThree';

function EditoralBoard() {

  const [api, contextHolder] = notification.useNotification();

  const options = boardStore.group.map(item => {
    return {
      label: `${item.challenge} (${item.count} Editor)`,
      value: item.challengeId,
    }              
  })

  const [graphData, setGraphData] = useState({
    chartOne: undefined,
    chartTwo: undefined,
    chartThree: undefined,
  });


  useEffect(() => {
    planStore.fetchList()
    boardStore.getGroup()
    fetchTask()
  }, [])

  const fetchTask = async () => {
    const data = await getGraphData()
    data.data.chartOne.mine = data.data.chartOne.avg.map(item => {
      return {
        startDate: item.startDate,
        endDate: item.endDate,
        count: undefined,
        planToRead: undefined,
      }
    })
    data.data.chartOne.avg = data.data.chartOne.avg.map(item => {
      return item
    })

    setGraphData(data.data)
  }
  
  const columns = [
    {
      title: 'Editor',
      dataIndex: 'editor',
      key: 'editor',
      render: (_, record) => {
        if (record.task?.user && record.task?.user?.username) {
          return <div>{record.task?.user?.username}</div>
        } 
        return null
      }
    },
    {
      title: 'Strategy',
      dataIndex: 'strategy',
      key: 'strategy',
      render: (_, record) => {
        return <a target="_blank" href={`/editor-discussion/${record.id}`} >{record.strategy}</a>
      }
    },
    {
      title: 'Votes Ranking',
      dataIndex: 'ranking',
      key: 'ranking',
      width: 250,
      render: (_, record) => {
        return <div className={styles.tableRanking}>
          <div className={styles.tableLike}>
            <div className={styles.tableLikeIcon}>
              <img src={Like} />
            </div>
            <div>{record.likes.length}</div>
          </div>
          <div className={styles.tableComment}>
            <div className={styles.tableCommentIcon}>
              <img src={Comment} />
            </div>
            <div>{record.comments.length}</div>
          </div>
          <div className={styles.tableView}>
            <div className={styles.tableViewIcon}>
              <img src={View} />
            </div>
            <div>{record.view}</div>
          </div>
        </div>
      }
    },
    {
      title: 'Commented',
      dataIndex: 'commented',
      key: 'commented',
      width: 200,
      render: (_, record) => {


        const comment = record.comments.find(item => item.uid === userStore.profile.id)

        if (comment)  {
          return <div className={styles.tableCommented}>
            <div className={styles.tableCommentedIcon}>
              <img src={Commented} />
            </div>
        </div>
        }
      }
    },
  ];


  return (
    <div className={styles.page}>
      <TabBar />
      {contextHolder}
      <div className={styles.wrapper}>
        <div className={styles.title}>Monitoring</div>
        <div className={styles.monitor}>
          <div className={styles.monitorWrapper}>
            <div className={styles.monitorTitle}>My and other editor’s reading article (number)</div>
            <div className={styles.graph}>
              <ChartOne data={graphData.chartOne}/>
            </div>
          </div>

          <div className={styles.monitorWrapper}>
            <div className={styles.monitorTitle}>My and other editor’s contribution for technique (number)</div>
            <div className={styles.graph}>
              <ChartTwo data={graphData.chartTwo}/>
            </div>
          </div>

          <div className={styles.monitorWrapper}>
            <div className={styles.monitorTitle}>Progress of strategy use</div>
            <div className={styles.graph}>
              <ChartThree data={graphData.chartThree}/>
            </div>
          </div>

        </div>
        
        <div className={styles.title}>Editorial Board</div>
        <div className={styles.editorialWrapper}>
          <div className={styles.editorSelector}>
            <Selector
              options={options}
              value={boardStore.selectedChallengeId}
              onChange={e => boardStore.changeSelected(e)}
              width={315}
            />
          </div>
          <div className={styles.editorTable}>
            <div className={styles.editorBanner} />
            <div className={styles.editorTable}>
              <Table
                loading={boardStore.loading}
                columns={columns}
                dataSource={boardStore.list}
                bordered
                pagination={false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(EditoralBoard);