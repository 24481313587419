import React, { useState, useEffect, useRef } from 'react';
import { message, Modal, Spin, Table, Steps, Input } from 'antd';
import { observer } from "mobx-react";
import readingStore from '../store/readingStore';
import ArticleConversation from '../components/ArticleConversation';
import Selector from '../components/Selector';
import styles from '../css/OwnPassage.module.scss';
import { ReadingLength, GenreType, ReadingInterest} from '../utils/constant';
import { useParams } from 'react-router';
import userStore from '../store/userStore';
import ReadingConversation from './ReadingConversation';
import Notification from '../img/notification.png'

const { TextArea } = Input;

function OwnPassage(props) {

  const params = useParams()

  const conversations = readingStore.reading.aiReadings

  const messagesEndRef = useRef(null)

  const scrollToBottom = () => {
      messagesEndRef.current?.scrollTo({ behavior: "smooth", top: messagesEndRef.current.scrollHeight})
  }

  useEffect(() => {
      scrollToBottom()
  }, [conversations]);

  return (
    <div className={styles.container}>

      <div className={styles.left}>

        <div className={styles.contain}>

          <div className={styles.option}>
            <div className={styles.optionTitle}>*The genre of article（types of text) I want to read <a className={styles.optionIcon} href="https://periwinkle-slash-7d2.notion.site/Understanding-Text-Types-4218b572d11e44419e8a54c02cbf51f7?pvs=4" target="_blank">
              <img src={Notification} />
              </a>
            </div>
           
            <div className={styles.optionSelector}>
              <Selector onChange={e => readingStore.genreType = e} width={450} options={GenreType.map(item => { return {value: item, label: item}})} />
            </div>
          </div>
            
          <div className={styles.option}>
            <div className={styles.optionTitle}>*The article length I want to read</div>
            <div className={styles.optionSelector}><Selector onChange={e => readingStore.articleLength = e} width={450} options={ReadingLength.map(item => { return {value: item, label: item}})}/></div>
          </div>

          {
            !!userStore.profile.hasInterest &&
            <div className={styles.option}>
              <div className={styles.optionTitle}>*The topic of my personal interests</div>
              {
                userStore.profile.hasInterest === 1 ?  <div className={styles.optionInput}>
                <TextArea 
                  placeholder="The topic could be a hobby or a life skill you have always wanted to develop (e.g., how to make a TikTok vlog) ; or information of importance to you, your family or your community (e.g., White people food）"
                  className={styles.textarea}
                  rows={5}
                  onChange={e => readingStore.interest = e.target.value}
                />
              </div> : <div className={styles.optionSelector}><Selector onChange={e => readingStore.interest = e} width={450} options={ReadingInterest.map(item => { return {value: item, label: item}})}/></div>
              }
            </div>
          }
         
        </div>

        <div className={styles.footerRight} onClick={() => readingStore.discoverArticle(!!userStore.profile.hasInterest)}>
            <div className={styles.text}>
              <div>Create ({readingStore.reading.aiReadingLeft} times left)</div>
            </div>
        </div>
      </div>

      <div className={styles.right}>
        <div className={styles.wrapperTitle}>
          Chat with Reade
        </div>
        <Spin spinning={readingStore.loading}>
          <div className={styles.wrapperContent} ref={messagesEndRef}>
            <ReadingConversation conversations={conversations} />
          </div>
        </Spin>
      </div>

    </div>
  )
}


export default observer(OwnPassage);