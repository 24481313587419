import React from 'react'
import { Select } from 'antd'
import DownArrow from '../img/down-arrow.png'

import styles from '../css/Selector.module.scss'

export default class Selector extends React.Component {

  constructor(props) {
    super(props)
  }


  render() {
    const { width, options, onChange, placeholder, value } = this.props

    return (
      <Select value={value} placeholder={placeholder || 'Please select'} options={options} onChange={onChange} style={{width: `${width}px`}} suffixIcon={<img className={styles.down} src={DownArrow}/>}/>
    )
  }
  
}
