import React, { useState, useEffect } from 'react';
import { Spin, Space, Button, notification, Table } from 'antd';
import styles from '../src/css/Entrance.module.scss'
import TabBar from './components/TabBar';
import Background from './components/Background';
import { useNavigate, useParams } from 'react-router-dom'
import { observer } from "mobx-react";
import readingStore from './store/readingStore';
import ChooseLevel from './components/ChooseLevel';
import OwnPassage from './components/OwnPassage';
import ReadingPage from './components/ReadingPage';
import userStore from './store/userStore';
import NewOwnPassage from './components/NewOwnPassage';
import NewReadingPage from './components/NewReadingPage';

function Level() {

  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [api, contextHolder] = notification.useNotification();
  const params = useParams()

  useEffect(() => {
    readingStore.getReadingTaskDetail(params.id)
  }, [])


  const experimental = userStore?.profile?.experimental

  return (
    <div className={styles.page}>
      <TabBar step={readingStore.reading.step} experimental={experimental}/>
      {contextHolder}

      <Background />

      {
        readingStore.reading.step === 0 && experimental && <ChooseLevel />
      }
      {
        readingStore.reading.step === 1 && experimental && <OwnPassage />
      }
      {
        readingStore.reading.step === 2 && experimental && <NewReadingPage />
      }

      {/* 对照组 */}
      {
        readingStore.reading.step !== 2 && !experimental && <NewOwnPassage /> 
      }
      {
        readingStore.reading.step === 2 && !experimental && <ReadingPage />
      }

    </div>
  )
}

export default observer(Level);