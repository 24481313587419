import React, { useState, useEffect, useRef } from 'react';
import { message, Modal, Spin, Table, Steps, Input } from 'antd';
import { observer } from "mobx-react";
import readingStore from '../store/readingStore';
import ArticleConversation from '../components/ArticleConversation';
import Selector from '../components/Selector';
import styles from '../css/NewOwnPassage.module.scss';
import { ReadingLength, GenreType, ReadingInterest} from '../utils/constant';
import { useParams } from 'react-router';
import userStore from '../store/userStore';
import ReadingConversation from './ReadingConversation';
import Notification from '../img/notification.png'

const { TextArea } = Input;

function OwnPassage(props) {

  const params = useParams()

  const conversations = readingStore.reading.aiReadings

  const messagesEndRef = useRef(null)

  const scrollToBottom = () => {
      messagesEndRef.current?.scrollTo({ behavior: "smooth", top: messagesEndRef.current.scrollHeight})
  }

  useEffect(() => {
      scrollToBottom()
  }, [conversations]);

  return (
    <div className={styles.container}>

      <div className={styles.right}>
        <div className={styles.wrapperTitle}>
          Chat with Reade
        </div>
        <Spin spinning={readingStore.loading}>
          <div className={styles.wrapperContent} ref={messagesEndRef}>
            <ReadingConversation conversations={conversations} />
          </div>
        </Spin>
      </div>

      <div>
        <div className={styles.footerRight} onClick={() => readingStore.discoverArticle(!!userStore.profile.hasInterest)}>
            <div className={styles.text}>
              <div>Create ({readingStore.reading.aiReadingLeft} times left)</div>
            </div>
        </div>
      </div>

    </div>
  )
}


export default observer(OwnPassage);