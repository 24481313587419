import { makeAutoObservable } from "mobx";
import { createPlan, getChallenge, getStrategy } from '../utils/request'
import { message } from 'antd';
import dayjs from 'dayjs'
import weekday from 'dayjs/plugin/weekday' // load on demand
import isoWeek from'dayjs/plugin/isoWeek'

dayjs.extend(weekday) // use plugin
dayjs.extend(isoWeek)

// Todo 總數據源
class PlanStore {

  challengeOptions = []
  strategyOptions = []

  startDate = undefined
  endDate = undefined
  planToRead = undefined
  frequency = 'daily'
  planReadMins = undefined

  submitLoading = false

  list = [{challenge: undefined, strategy: undefined},{challenge: undefined, strategy: undefined},{challenge: undefined, strategy: undefined}]

  constructor() {
    makeAutoObservable(this);
    this.init()
  }

  
  init() {
    const now = dayjs();

    const monday = now.startOf('isoWeek').isoWeekday(1).format('YYYY-MM-DD');
    const sunday = now.startOf('isoWeek').isoWeekday(7).format('YYYY-MM-DD');

    this.startDate = monday
    this.endDate = sunday
  }


  async fetchList() {
    if (this.challengeOptions.length > 0) {
      return false
    }
    const result = await getChallenge()

    const arr = result.data.reduce((group, item) => {
      const { dimension } = item;
      group[dimension] = group[dimension] ?? [];
      group[dimension].push(item);
      return group;
    }, {})

    const options = Object.keys(arr).map(key => {
      return {
        label: key, 
        options: arr[key].map(item => {
          return {
            value: item.challengeId,
            label: item.challenge
          }
        })
      }
    })

    const strategy = await getStrategy()
    this.challengeOptions = options
    this.strategyOptions = strategy.data

  }


  add = () => {
    console.log(1)
    if (this.list.length === 6) {
      message.error('You can only add up to 6 challenges')
      return
    }
    this.list.push({challenge: undefined, strategy: undefined})
  }

  delete = (index) => {
    if (this.list.length === 1) {
      message.error('You must have at least one challenge')
      return
    }
    this.list.splice(index, 1)
  }

  changeItem = (index, value) => {
    this.list[index] = {
      ...this.list[index],
      ...value
    }
  }

  submit = async() => {
    if (!this.planToRead) {
      message.error('Please select the number of articles you plan to read')
      return
    } else if (!this.planReadMins) {
      message.error('Please input the number of minutes you plan to read')
      return
    } else if (this.list.some(item => !item.challengeId || !item.strategyId)) {
      message.error('Please fill in the challenge and strategy')
      return
    }
    return await this.submitPlan()
  }

   submitPlan = async() => {
    this.submitLoading = true
    try {
      const result = await createPlan({
        startDate: this.startDate,
        endDate: this.endDate,
        planToRead: this.planToRead,
        frequency: this.frequency,
        planReadMins: this.planReadMins,
        strategyIds: this.list.map(item => item.strategyId)
      })
      this.submitLoading = false
      return result 
    } catch(e) {
      this.submitLoading = false
    }

   }



}



const planStore = new PlanStore();
export default planStore;
