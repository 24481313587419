import { createBrowserRouter } from 'react-router-dom';
import Root from './Root'
import Login from './Login';
import MyAccount from './MyAccount';
import CreatePlan from './CreatePlan'
import ArticleDiscovery from './ArticleDiscovery'
import InsightfulReview  from './InsightfulReview'
import Main from './Main'
import EditorDiscussion  from './EditorDiscussion'
import Explor from './Explor'
import ReflectionAndAdjustment  from './ReflectionAndAdjustment'
import ViewUserDetail from './ViewUserDetail';
import ViewUserDetailReading from './ViewUserDetailReading';
import ManageUser from './ManageUser';
import CRM from './CRM';
import ViewUserData from './ViewUserData';
import ViewUserDataReading from './ViewUserDataReading';
import EditoralBoard from './EditoralBoard';

// 新系统页面
import Entrance from './Entrance'
import Achievement from './Achievement'
import History from './History'
import TaskHistory from './TaskHistory'
import Level from './Level'

const router = createBrowserRouter([
    {
      path: "/",
      element: <Root />,
      errorElement: <h1>404 Not Found</h1>,
      children: [
        {
          path: "/",
          element: <Login />,
        },
        {
          path: "login",
          element: <Login />,
        },
        {
          path: "entrance",
          element: <Entrance />,
        },
        {
          path: "achievement",
          element: <Achievement />,
        },
        {
          path: "history",
          element: <History />,
        },
        {
          path: "task-history",
          element: <TaskHistory />,
        },
        {
          path: "level/:id",
          element: <Level />,
        },
        {
          path: "my-account",
          element: <MyAccount />,
        },
        {
          path: "create-plan",
          element: <CreatePlan />,
        },
        {
          path: "article-discovery/:id",
          element: <ArticleDiscovery />,
        },
        {
          path: "insightful-review/:id",
          element: <InsightfulReview />,
        },
        {
          path: "main",
          element: <Main />
        },
        {
          path: "editor-discussion/:id",
          element: <EditorDiscussion />,
        },
        {
          path: "explore/:id",
          element: <Explor />,
        },
        {
          path: "reflection-and-adjustment",
          element: <ReflectionAndAdjustment />,
        },
        {
          path: "admin-manage-user",
          element: <ManageUser />,
        },
        {
          path: "admin-view-data",
          element: <ViewUserData />,
        },
        {
          path: "admin-view-data-reading",
          element: <ViewUserDataReading />,
        },
        {
          path: "view-user-detail",
          element: <ViewUserDetail />,
        },
        {
          path: "view-user-detail-reading",
          element: <ViewUserDetailReading />,
        },
        {
          path: "admin-crm",
          element: <CRM />,
        },
        {
          path: "editoral-board",
          element: <EditoralBoard />,
        },
      ],
    },
  ])

  export default router