import React from 'react'
import styles from '../css/Heart.module.css'
import Active from '../img/active.png'
import Inactive from '../img/inactive.png'

export default class Heart extends React.Component {

  constructor(props) {
    super(props)
  }

  handleClick = (changedScore) => {
    const { onChange, disabled, score} = this.props
    if (score === changedScore) {
      !disabled && onChange && onChange(0)
      return
    }
    !disabled && onChange && onChange(changedScore)
  }

  render() {
    const { score } = this.props
    const arr = [1,2,3,4,5]

    return (
      <div className={styles.group}>
        {
          arr.map(item => {
            return (
              <div className={[styles.score, item <= Number(score) ? styles.active : ''].join(' ')} onClick={() => { this.handleClick(item)}}>
                <img src={item <= Number(score) ? Active : Inactive} className={styles.heart} />
              </div>
            )
          })
        }
       
      </div>
    )
  }
  
}
